import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import { AiOutlineDownload } from 'react-icons/ai';
// import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
import { roundOfDigit, millisecondsToHourMinute } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { ACCESS_REPORT, DOWNLOAD_ACCESS_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import ReactPagination from 'components/general/ReactPagination';
import fileDownload from 'js-file-download';
import { MdRefresh } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
// import { FETCH_CITY, FETCH_STATE } from 'actions/address';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { FETCH_USER } from 'actions/user';
import { FETCH_VEHICLE } from 'actions/vehicle';

const AccessReport = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  // const [allaccessReportData, setAllaccessReportData] = useState([]);
  // const [fieldValue, setFieldValue] = useState('All');
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  // const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  // const [selectedStationOptions, setSelectedStationOptions] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});

  const accessReportList = useSelector((state) => state.accessReport.accessReport.results);
  const isLoader = useSelector((state) => state.accessReport.isLoading);
  const page = useSelector((state) => state.accessReport.accessReport.page);
  const totalData = useSelector((state) => state.accessReport.accessReport.totalResults);
  const limit = useSelector((state) => state.accessReport.accessReport.limit);
  const totalPages = useSelector((state) => state.accessReport.accessReport.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const role = useSelector((state) => state.profile.userProfile.role);
  // const allChargingStations = useSelector((state) => state.chargingStation.chargingStations);
  // const allCity = useSelector((state) => state.city.city);
  // const allState = useSelector((state) => state.state.states);

  // const stationFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allChargingStations, (station) => {
  //     return { label: station.name, value: station.id };
  //   }),
  // ];

  // const stateFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allState, (state) => {
  //     return { label: state.name, value: state.name };
  //   }),
  // ];

  // const cityFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allCity, (city) => {
  //     return { label: city.name, value: city.name };
  //   }),
  // ];

  const accessReport = useCallback(
    (value) => {
      if (searchText) {
        const accessReportData = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          search: searchText,
        };
        dispatch({ type: ACCESS_REPORT, payload: accessReportData });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({ type: ACCESS_REPORT, payload: data });
      }
    },
    [startDate, endDate, searchText]
  );

  const downloadaccessReport = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      const data = {
        ...advanceFilterData,
        excel: true,
        from: moment(startDate).utc(true),
        to: moment(endDate).utc(true),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      dispatch({
        type: DOWNLOAD_ACCESS_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              if (role === 'doco') {
                fileDownload(res.data, `${'Charging Sessions Report'}.xlsx`);
              } else {
                fileDownload(res.data, `${'Partner Charging Sessions Report'}.xlsx`);
              }
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        from: moment(startDate).utc(true),
        to: moment(endDate).utc(true),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      dispatch({
        type: DOWNLOAD_ACCESS_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              if (role === 'doco') {
                fileDownload(res.data, `${'Charging Sessions Report'}.xlsx`);
              } else {
                fileDownload(res.data, `${'Partner Charging Sessions Report'}.xlsx`);
              }
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadaccessReport();
  };

  useEffect(() => {
    accessReport();
  }, []);

  // useEffect(()=>{
  //   const data = {
  //     state: _.map(selectedStateOptions, (item) => item.value),
  //     city: _.map(selectedCityOptions, (item) => item.value),
  //     station: _.map(selectedStationOptions, (item) => item.value),
  //   }
  //     accessReport(data);
  // },[selectedStateOptions,selectedCityOptions,selectedStationOptions])

  // useEffect(() => {
  //   dispatch({ type: FETCH_STATE, payload: {} });
  //   dispatch({ type: FETCH_CHARGING_STATION, payload: {} });
  // }, []);

  // const getCityByState = useCallback((stateName = '') => {
  //   dispatch({ type: FETCH_CITY, payload: { state_name: stateName } });
  // }, []);

  // function getDropdownButtonLabel({ placeholderButtonLabel }) {
  //     return `${placeholderButtonLabel}`;
  // }

  // function stateOnChange(value, event) {
  //   if (event.action === 'select-option' && event.option.value === 'All') {
  //     this.setState(this.options);
  //     getCityByState(_.get(event, 'option.value'));
  //   } else if (event.action === 'deselect-option' && event.option.value === 'All') {
  //     this.setState([]);
  //   } else if (event.action === 'deselect-option') {
  //     this.setState(value.filter((o) => o.value !== 'All'));
  //   }
  //   // else if (value.length === this.options.length - 1) {
  //   //   this.setState(this.options);
  //   //   getCityByState(_.get(event, 'option.value'));
  //   // }
  //   else {
  //     this.setState(value);
  //     getCityByState(_.get(event, 'option.value'));
  //   }
  // }

  // function onChange(value, event) {
  //   if (event.action === 'select-option' && event.option.value === 'All') {
  //     this.setState(this.options);
  //   } else if (event.action === 'deselect-option' && event.option.value === 'All') {
  //     this.setState([]);
  //   } else if (event.action === 'deselect-option') {
  //     this.setState(value.filter((o) => o.value !== 'All'));
  //   }
  //   // else if (value.length === this.options.length - 1) {
  //   //   this.setState(this.options);
  //   // }
  //   else {
  //     this.setState(value);
  //   }
  // }

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      if (searchText) {
        const filterSearchData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
        };
        accessReport(filterSearchData);
      } else {
        const filterDateData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        accessReport(filterDateData);
      }
    }
  }, [startDate, endDate, sortByItem, searchText]);

  // const filterData = [
  //   { label: 'Newest', value: 'Newest' },
  //   { label: 'Oldest', value: 'Oldest' },
  //   { label: 'All', value: 'All' },
  // ];

  // useEffect(() => {
  //   const newestData = {
  //     sortBy: '1',
  //   };
  //   fieldValue === 'Newest' && accessReport(newestData);
  //   const oldestData = {
  //     sortBy: '-1',
  //   };
  //   fieldValue === 'Oldest' && accessReport(oldestData);
  //   fieldValue === 'All' && accessReport();
  // }, [fieldValue]);

  // useEffect(() => {
  //   setAllaccessReportData(
  //     _.filter(
  //       accessReportList,
  //       (item) =>
  //         startsWith(_.get(item, 'name'), searchText) ||
  //         startsWith(_.get(item, 'state'), searchText) ||
  //         startsWith(_.get(item, 'city'), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_scheduled_amount'), 2).toString(), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_cancelled_amount'), 2).toString(), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_completed_amount', ''), 2).toString(), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_returned_amount', ''), 2).toString(), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_tax_amount', ''), 2).toString(), searchText)
  //     )
  //   );
  // }, [searchText, accessReportList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && accessReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        accessReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && accessReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        accessReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
          page: page.selected + 1,
        };
        accessReport(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        accessReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        accessReport(data);
      }
    },
    [sortByItem, startDate, endDate, searchText]
  );

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const allUsers = useSelector((state) => state.user.users);
  const allVehicles = useSelector((state) => state.vehicle.vehicles);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { company: '', charging_station: '' };

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  const getAllUsers = useCallback((data) => {
    dispatch({
      type: FETCH_USER,
      payload: data ? data : null,
    });
  }, []);

  const getVehicle = useCallback((data) => {
    dispatch({ type: FETCH_VEHICLE, payload: data ? data : {} });
  }, []);

  useEffect(() => {
    getAllStation();
    getAllUsers({ role: 'doco' });
    getVehicle();
  }, []);

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={`${role === 'doco' ? 'Charging Sessions' : 'Partner Charging Sessions'}`} />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 'auto' : 'auto'}`}
                      md={`${showSearchBar ? 'auto' : 'auto'}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    {/* <Col xl={2} md={4}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="log-filter-block">
                              <div className="log-filter-icon">
                                <BsFilter />
                                <span className="log-filter-label">{fieldValue}</span>
                              </div>
                              <div className="log-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="log-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              onClick={() => {
                                setFieldValue(item.value);
                              }}
                            >
                              {item.label}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col> */}
                    <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                      <div className="data-report-date_picker">
                        {/* <DatePickerWithFloatingLabel
                          defaultValueOfStartDate={new Date()}
                          defaultValueOfEndDate={new Date()}
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                        /> */}
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={startDate}
                          initialValueOfEndDate={endDate}
                        />
                      </div>
                    </Col>
                    <Col xl={'auto'} md={'auto'}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={'auto'} md={'auto'}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <Button className="report-search-button">Advance Filter</Button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                      <div className="report-excel-icon">
                        <BsDownload size={28} color={'#3c7cdd'} />
                      </div>
                    </Col>
                    {/* <Col xl={1} md={2}>
                      <div className="data-report__table-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                  </Row>
                  {/* <Row>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={stateFilter}
                        placeholderButtonLabel="Select state"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedStateOptions}
                        onChange={stateOnChange}
                        setState={setSelectedStateOptions}
                        hideSearch={true}
                      />
                    </Col>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={cityFilter}
                        placeholderButtonLabel="Select city"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedCityOptions}
                        onChange={onChange}
                        setState={setSelectedCityOptions}
                        hideSearch={true}
                      />
                    </Col>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={stationFilter}
                        placeholderButtonLabel="Select Station"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedStationOptions}
                        onChange={onChange}
                        setState={setSelectedStationOptions}
                        hideSearch={true}
                      />
                    </Col>
                  </Row> */}
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charger ID</span>
                              <span className="ico" onClick={() => handleSorting('charger_id', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          {role === 'doco' ? null : (
                            <th>
                              <div className="sorting">
                                <span>Company Name</span>
                                <span className="ico" onClick={() => handleSorting('charger_id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                          )}
                          <th>
                            <div className="sorting">
                              <span>Vehicle MAC</span>
                              <span className="ico" onClick={() => handleSorting('mac', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Vehicle Registration No.</span>
                              <span className="ico" onClick={() => handleSorting('vin_num', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Start Date & Time</span>
                              <span className="ico">
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Stop Date & Time</span>
                              <span className="ico">
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Unit Consumption (kW)</span>
                              <span className="ico">
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Amount (AED)</span>
                              <span className="ico">
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>PLU</span>
                              <span className="ico" onClick={() => handleSorting('PLU', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Brand</span>
                              <span className="ico" onClick={() => handleSorting('make', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charging Duration(hh:mm:ss)</span>
                              <span className="ico">
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={9} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(accessReportList) ? (
                          <tr>
                            {role == 'doco' ? (
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">No Charging Session Report Found</div>
                              </td>
                            ) : (
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">No Partner Charging Session Report Found</div>
                              </td>
                            )}
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(accessReportList, (item, key) => {
                            const serial_num = limit * (page - 1) + key;

                            const kwAmount = (_.get(item, 'meterstop', '') - _.get(item, 'meterstart', '')) / 1000;

                            const tariff = _.get(item, 'tariff', '');

                            const timeTaken = moment(item.booking_stop) - moment(item.booking_start);
                            return (
                              <>
                                <tr key={`access-report-${serial_num}`}>
                                  <td>{serial_num + 1}</td>
                                  <td>{_.get(item, 'charger.charger_id', '')}</td>
                                  {role === 'doco' ? null : <td>{_.get(item, 'vehicle.owner.company', '')}</td>}
                                  <td>{_.get(item, 'vehicle.mac', '')}</td>
                                  <td>{_.get(item, 'vehicle.vin_num', '')}</td>
                                  <td>{moment(item.booking_start).format('DD/MM/YYYY H:mm:ss')}</td>
                                  <td>{moment(item.booking_stop).format('DD/MM/YYYY H:mm:ss')}</td>
                                  <td>{roundOfDigit(kwAmount, 2)}</td>
                                  <td>{roundOfDigit(kwAmount * tariff, 2)}</td>
                                  <td>{_.get(item, 'PLU', '')}</td>
                                  <td>{_.get(item, 'vehicle.make', '')}</td>
                                  <td>
                                    {millisecondsToHourMinute(timeTaken)}:{moment.duration(timeTaken).seconds()}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(accessReportList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.company && delete values['company'];
              !values.make && delete values['make'];
              !values.charging_station && delete values['charging_station'];
              accessReport(values);
              handleAdvanceFilterData(values);
              onCloseAdvanceFilterPopup();
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label="Company"
                      options={_.map(allUsers, (item) => {
                        return { label: item.company, value: item.id };
                      })}
                      placeholder="Select Company"
                      name="company"
                      value={values.company}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        setFieldValue(`company`, val);
                      }}
                    />
                    <Select
                      label="Vehicle Brand"
                      options={_.map(allVehicles, (item) => {
                        return { label: item.make, value: item.make };
                      })}
                      placeholder="Select Vehicle Brand"
                      name="make"
                      value={values.make}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        setFieldValue(`make`, val);
                      }}
                    />
                    <Select
                      label="Station"
                      options={_.map(allStation, (station) => {
                        return { label: station.name, value: station.id };
                      })}
                      placeholder="Select Station"
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        setFieldValue(`charging_station`, val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      accessReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdRefresh size={24} className="reset--icon" />
                    Reset
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default AccessReport;
