import React, { useCallback, useState, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { parsePhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
// import ProfilePicInput from 'components/inputs/ProfilePicInput';
import OtpConfirmationModal from '../setting/OtpConfirmationModal';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_OTP } from 'actions/otp';
import * as Yup from 'yup';
import { CREATE_USER, GET_USER, UPDATE_USER } from 'actions/user';
// import { FETCH_ROLE } from 'actions/role';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { DOCO } from 'components/common/constant';
import { FETCH_TARIFF } from 'actions/tariff';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
// import queryString from 'query-string';
// import axios from 'axios';
// import { cookie } from 'utils/cookies/cookies';
// import RadioButton from 'components/inputs/RadioButton';
// import DatePickerInput from 'components/inputs/DatePickerInput';

const AddBusinessPartnerSchema = Yup.object({
  // phone: Yup.string().required('Phone number is required'),
  // address: Yup.string().required('address is required'),
  // gender: Yup.string().required('gender is required'),
  // dob: Yup.string().required('birthDate is required'),
  name: Yup.string()
    .required('Name is required')
    .strict(true)
    .trim('Space is not allowed')
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed')
    .max(100, 'Text limit should be less than 100 characters.'),
  password: Yup.string().required('Password is required').strict(true).trim('Space is not allowed'),
  email: Yup.string()
    .required('email is required')
    .strict(true)
    .trim('Space is not allowed')
    .email('Please enter valid email id')
    .max(100, 'Text limit should be less than 100 characters.'),
  // role: Yup.string().required('Select role'),
  company: Yup.string().required('Company Name is required'),
  tariff: Yup.string().required('Select PLU'),
});

const AddBusinessPartner = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const history = useHistory();
  //   const token = cookie.get('token');
  const [showOtpModal, setShowOtpModal] = useState(false);
  // const [phoneError, setPhoneError] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneData, setPhoneData] = useState({});
  const [isOtpVerify, setIsOtpVerify] = useState(false);
  // const [browseFileName, setBrowseFileName] = useState('');
  //   const [isImgLoading, setIsImgLoading] = useState(false);

  const allTariffList = useSelector((state) => state.tariff.tariff);
  const userDetails = useSelector((state) => state.user.userDetail);
  const isLoader = useSelector((state) => state.user.isLoading);
  //   const allRoles = useSelector((state) => state.role.roles);
  //   const rolePage = useSelector((state) => state.role.page);
  //   const roleTotalPage = useSelector((state) => state.role.totalPages);
  const getCountryCode = _.get(parsePhoneNumber(countryCode), 'countryCallingCode', '');
  const getPhoneNumber = _.get(parsePhoneNumber(countryCode), 'nationalNumber', '');

  const getUserData = useCallback(() => {
    dispatch({ type: GET_USER, payload: userId });
  }, []);

  const sendOtp = useCallback((data) => {
    dispatch({
      type: SEND_OTP,
      payload: data,
      cb: (res) => {
        if (res && res.status === 201) {
          toast.success('OTP sent to your phone number.');
        }
      },
    });
  }, []);

  //   const getAllRoles = useCallback((data = {}) => {
  //     dispatch({ type: FETCH_ROLE, payload: data });
  //   }, []);

  const addUser = useCallback((data) => {
    dispatch({
      type: CREATE_USER,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          window.location.href = '/businessPartner';
        }
      },
    });
  }, []);

  const editUser = useCallback((data) => {
    dispatch({
      type: UPDATE_USER,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          window.location.href = '/businessPartner';
        }
      },
    });
  }, []);

  const getTariff = useCallback((data = {}) => {
    dispatch({ type: FETCH_TARIFF, payload: data });
  }, []);

  useEffect(() => {
    userId && getUserData();
    getTariff();
  }, []);

  //   const handleImage = (image, savePath, setFieldValue) => {
  //     const fileName = _.get(image, 'name', '');
  //     setIsImgLoading(true);

  //     axios({
  //       method: 'get',
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
  //     })
  //       .then((res) => {
  //         if (res.status === 201) {
  //           const signedRequest = _.get(res, 'data.signedRequest', '');
  //           handleUploadFile(signedRequest, image, savePath, setFieldValue);
  //         } else {
  //           toast.error(res.data.message);
  //         }
  //       })
  //       .catch((e) => console.error('Error -> ', e));
  //   };

  //   const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
  //     const fileType = _.get(imageData, 'type', '');

  //     axios({
  //       method: 'put',
  //       headers: {
  //         'Content-Type': fileType,
  //         'Content-Encoding': 'base64',
  //       },
  //       url: url,
  //       data: imageData,
  //     })
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setIsImgLoading(false);
  //           const imgUrlData = queryString.parseUrl(url);
  //           setFieldValue(savePath, imgUrlData.url);
  //         } else {
  //           toast.error(res.data.message);
  //         }
  //       })
  //       .catch((e) => console.error('Error -> ', e));
  //   };

  return (
    <LayoutContainer>
      <AdminHeader title={`${userId ? 'Edit' : 'Add'} Business Partner`} backTo="/businessPartner" />
      <div className="page-content-wrapper scrollable">
        <div className="profile-form-page__main">
          {isLoader ? (
            <div className="loader-block___">
              <Spinner as="span" animation="border" size="sm" role="status" />
              <span className="ps-2">Loading...</span>
            </div>
          ) : (
            <Card>
              <div className="profile-form-page--inner">
                <Formik
                  initialValues={
                    userId
                      ? { ...userDetails, phone: `+${userDetails.country_code} ${userDetails.phone}`.toString() }
                      : {
                          name: '',
                          phone: '',
                          email: '',
                          role: DOCO,
                          // access: '',
                          country_code: '971',
                          password: '',
                          picture: '',
                          address: '',
                          gender: '',
                          dob: '',
                          company: '',
                          tariff: '',
                        }
                  }
                  validationSchema={AddBusinessPartnerSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (isOtpVerify) {
                      // send otp api calling...
                      const phoneNumberDetails = {
                        country_code: getCountryCode,
                        number: getPhoneNumber,
                      };
                      sendOtp(phoneNumberDetails);
                      setPhoneData(phoneNumberDetails);
                      setShowOtpModal(true);
                    } else if (userId) {
                      const userData = _.omit(values, [
                        'settings',
                        'wallet_balance',
                        'isPhoneVerified',
                        'isActive',
                        'termsAccepted',
                        'favorites',
                        'tenant',
                        'vehicles',
                        'rfid',
                      ]);
                      editUser({ ...userData, country_code: getCountryCode, phone: getPhoneNumber });
                    } else {
                      //add user api
                      addUser({ ...values, country_code: getCountryCode, phone: getPhoneNumber });
                    }

                    setSubmitting(false);
                  }}
                >
                  {({ values, handleSubmit, handleChange, setFieldValue, isSubmitting, errors, touched }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        {/* <Col xxl={2} xl={3} lg={5} md={4}>
                        <div className="d-flex justify-content-center">
                          <ProfilePicInput
                            showAdd
                            name="picture"
                            path={values.picture}
                            showLoader={isImgLoading}
                            onChange={(e) => handleImage(e.currentTarget.files[0], 'picture', setFieldValue)}
                          />
                        </div>
                      </Col> */}
                        <Col>
                          <div className="profile-form__block">
                            <Row>
                              <Col xl={6} lg={12}>
                                <TextInput
                                  isRequired
                                  disabled
                                  label="Role"
                                  placeholder="role"
                                  name="role"
                                  value={'Fleet_Owner'}
                                  onChange={(val) => setFieldValue('role', val.target.value)}
                                  error={errors.role && touched.role ? errors.role : null}
                                />
                              </Col>
                              <Col xl={6} lg={12}>
                                <TextInput
                                  isRequired
                                  label="Company"
                                  placeholder="Company Name"
                                  name="company"
                                  value={values.company}
                                  onChange={handleChange}
                                  error={errors.company && touched.company ? errors.company : null}
                                />
                              </Col>
                              <Col xl={6} lg={12}>
                                <TextInput
                                  isRequired
                                  label="Name"
                                  placeholder="User Name"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  error={errors.name && touched.name ? errors.name : null}
                                />
                              </Col>
                              <Col xl={6} lg={12}>
                                <TextInput
                                  isRequired
                                  label="Email"
                                  placeholder="Email"
                                  name="email"
                                  type="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  error={errors.email && touched.email ? errors.email : null}
                                />
                              </Col>
                              <Col xl={6} lg={12}>
                                <PhoneNumberInput
                                  label="Phone No."
                                  name="phone"
                                  value={values.phone}
                                  onChange={(val) => {
                                    setFieldValue(`phone`, val);
                                    setCountryCode(val || '');
                                  }}
                                  // error={errors.phone && touched.phone ? errors.phone : phoneError ? phoneError : null}
                                />
                              </Col>
                              <Col xl={6} lg={12}>
                                <TextInput
                                  isRequired
                                  label="Password"
                                  placeholder="Password"
                                  type="password"
                                  name="password"
                                  autoComplete="new-password"
                                  value={values.password}
                                  onChange={handleChange}
                                  error={errors.password && touched.password ? errors.password : null}
                                />
                              </Col>
                              <Col xl={6} lg={12}>
                                <Select
                                  isRequired
                                  className="roles-select__box"
                                  label="PLU"
                                  placeholder="PLU"
                                  name="tariff"
                                  options={_.map(allTariffList, (item) => {
                                    return { label: _.startCase(item?.plu), value: item?.id };
                                  })}
                                  value={values.tariff}
                                  onChange={(val) => {
                                    setFieldValue('tariff', val);
                                  }}
                                  error={errors.tariff && touched.tariff ? errors.tariff : null}
                                />
                              </Col>
                              {/* <Col xl={6} lg={12}> */}
                              {/* User role list are change when role is accepted from BE. */}
                              {/* <Select
                                isRequired
                                label="Role"
                                name="role"
                                placeholder="Select Role"
                                value={values.role}
                                options={_.map(allRoles, (item) => {
                                  return { label: _.startCase(item.name), value: item.name };
                                })}
                                onMenuScrollDown={true}
                                getDataOnScrollDown={getAllRoles}
                                page={rolePage}
                                totalPage={roleTotalPage}
                                onChange={(val) => setFieldValue(`role`, val)}
                                error={errors.role && touched.role ? errors.role : null}
                              />
                            </Col> */}
                              {/* <Col xl={6} lg={12}>
                              <div className="gender-field__block">
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={values.gender === 'male'}
                                  onChange={() => setFieldValue('gender', 'male')}
                                >
                                  Male
                                </RadioButton>
                                <RadioButton
                                  containerClassName="radio-field"
                                  checked={values.gender === 'female'}
                                  onChange={() => setFieldValue('gender', 'female')}
                                >
                                  Female
                                </RadioButton>
                              </div>
                            </Col> */}
                              {/* <Col xl={6} lg={12}>
                              <DatePickerInput
                                label="Date of birth"
                                name="dob"
                                value={values.dob}
                                onChange={(dateString) => setFieldValue('dob', dateString)}
                                // error={errors.dob && touched.dob ? errors.dob : null}
                              />
                            </Col> */}
                              {/* <Col lg={6} md={12}>
                              <TextInput
                                label="Address"
                                placeholder="Address"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                // error={errors.address && touched.address ? errors.address : null}
                              />
                            </Col> */}

                              <Col md={12}>
                                <span className="save-btn--block">
                                  <Button type="submit" className="profile-save-btn" disabled={isSubmitting}>
                                    {isOtpVerify ? 'Send OTP' : 'Save'}
                                  </Button>
                                </span>
                                <span className="cancel-btn--block">
                                  <Button className="cancel-btn" onClick={() => history.push(`/businessPartner`)}>
                                    Cancel
                                  </Button>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
              {showOtpModal && (
                <Modal show={showOtpModal} centered size="lg">
                  <OtpConfirmationModal onClose={() => setShowOtpModal(false)} isVerifyOtp={(val) => setIsOtpVerify(val)} phoneData={phoneData} />
                </Modal>
              )}
            </Card>
          )}
        </div>
      </div>
    </LayoutContainer>
  );
};
export default AddBusinessPartner;
