import React, { useEffect, useCallback } from 'react';
import Card from 'components/inputs/Card';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import DummyImage from 'assets/images/icons/dummyImage.png';
import Avatar from 'components/inputs/Avatar';
import Button from 'components/inputs/Button';
import Profile from './Profile';
import { useDispatch, useSelector } from 'react-redux';
import ChangePassword from './ChangePassword';
import RolesAndAccess from './RoleAccess';
import { AiOutlinePlus } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { GET_PROFILE } from 'actions/profile';
import _ from 'lodash';
import { cookie } from 'utils/cookies/cookies';
import { TENANT_ADMIN, DOCO } from 'components/common/constant';
import { Spinner } from 'react-bootstrap';

const Setting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileDetail = useSelector((state) => state.profile.userProfile);
  const isLoader = useSelector((state) => state.profile.isLoading);
  const userRole = cookie.get('user-role');

  const getProfile = useCallback(() => {
    dispatch({ type: GET_PROFILE });
  }, []);

  useEffect(() => {
    getProfile();
  }, []);

  const { tab } = queryString.parse(location.search);

  const setTab = (e, tab) => {
    e.preventDefault();
    history.push(`/setting?tab=${tab}`);
  };

  useEffect(() => {
    if (!tab) {
      history.push('/setting?tab=profile');
    }
  }, [tab]);

  return (
    <React.Fragment>
      <LayoutContainer className="layout-container-main-page">
        <AdminHeader title="Settings" />
        <div className="page-content-wrapper scrollable">
          {isLoader ? (
            <div className="setting-page__main">
              <div className="loader--block">
                <Spinner as="span" animation="border" size="lg" role="status" />
                <span className="ps-2">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="setting-page__main">
              <Card>
                <div className="page--info">
                  <div className="page-avatar">
                    <Avatar imgSrc={DummyImage} name="userProfile" />
                  </div>
                  <div className="user-content">
                    <span className="user-name">{_.get(profileDetail, 'name', '')}</span>
                    <span className="user-info">
                      {_.get(profileDetail, 'role') == 'doco' ? 'Fleet_Owner' : _.startCase(_.get(profileDetail, 'role', ''))}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="tab-block">
                    <div className="tab-nav-block">
                      <a
                        href="#"
                        className={`tab-nav__item setting-font ${tab === 'profile' ? 'active' : ''}`}
                        onClick={(e) => setTab(e, 'profile')}
                        data-content="tab-profile"
                      >
                        Profile
                      </a>
                      {(userRole === TENANT_ADMIN || userRole === DOCO) && (
                        <a
                          href="#"
                          className={`tab-nav__item setting-font ${tab === 'role' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'role')}
                          data-content="tab-role"
                        >
                          Roles & Access
                        </a>
                      )}

                      {/*  <a
                      href="#"
                      className={`tab-nav__item setting-font ${tab === 'changePassword' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'changePassword')}
                      data-content="tab-changePassword"
                    >
                      Change password
                    </a> */}
                      {tab === 'profile' && userRole === TENANT_ADMIN && (
                        <div className="tab-nav-block__right">
                          <Button onClick={() => history.push('/setting/profile')}>Edit Profile</Button>
                        </div>
                      )}
                      {tab === 'role' && (userRole === TENANT_ADMIN || userRole === DOCO) && (
                        <div className="tab-nav-block__right">
                          <Button onClick={() => history.push('/setting/addUser')}>
                            <span className="me-2">
                              <AiOutlinePlus color={'#FFFFFF'} />
                            </span>
                            Add User
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="tab-content-block">
                      <div className="tab-content__item active" id="setting-tab">
                        {tab === 'profile' && <Profile />}
                        {tab === 'changePassword' && <ChangePassword />}
                        {(userRole === TENANT_ADMIN || userRole === DOCO) && tab === 'role' && <RolesAndAccess />}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default Setting;
