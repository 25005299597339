import React, { useCallback, useEffect } from 'react';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, FieldArray, getIn } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_TARIFF, GET_TARIFF, UPDATE_TARIFF } from 'actions/tariff';
import _ from 'lodash';
import * as Yup from 'yup';
import { FETCH_OEM } from 'actions/oem';
import Select from 'components/inputs/Select';
import SessionHeading from 'components/general/SessionHeading';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_USER_GROUP } from 'actions/userGroup';
import CheckBox from 'components/inputs/CheckBox';
import { useHistory, useParams } from 'react-router';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';

const TariffSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  schedule: Yup.array().of(
    Yup.object().shape({
      price_per_unit: Yup.number().required('Price per unit is required').min(0, 'Enter valid price'),
      from: Yup.string().required('From is required'),
      to: Yup.string().required('To is required'),
    })
  ),
});

const PricingForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tariffId } = useParams();

  const allOems = useSelector((state) => state.oem.oems);
  const allUserGroup = useSelector((state) => state.userGroup.userGroups);
  const userGroupPage = useSelector((state) => state.userGroup.page);
  const chargeSpotPage = useSelector((state) => state.oem.page);
  const userGroupTotalPage = useSelector((state) => state.userGroup.totalPages);
  const chargeSpotTotalPage = useSelector((state) => state.oem.totalPages);
  const tariffDetail = useSelector((state) => state.tariff.tariffDetail);

  const addTariff = useCallback((data) => {
    dispatch({
      type: CREATE_TARIFF,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          window.location.href = '/tariffs';
        }
      },
    });
  }, []);

  const updateTariff = useCallback((data) => {
    dispatch({
      type: UPDATE_TARIFF,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          window.location.href = '/tariffs';
        }
      },
    });
  }, []);

  const getAllUserGroup = useCallback((data = {}) => {
    dispatch({ type: FETCH_USER_GROUP, payload: data });
  }, []);

  const getAllOem = useCallback((data = {}) => {
    dispatch({ type: FETCH_OEM, payload: data });
  });

  const getTariffDetail = useCallback((id) => {
    dispatch({ type: GET_TARIFF, payload: id });
  }, []);

  useEffect(() => {
    if (tariffId) {
      getTariffDetail(tariffId);
    }
  }, [tariffId]);

  const initialValues =
    tariffId && !_.isEmpty(tariffDetail) && !_.isEmpty(tariffDetail.oem)
      ? { ...tariffDetail, oem: _.get(tariffDetail, 'oem.id', '') }
      : tariffId && tariffDetail.oem === undefined
      ? { ...tariffDetail, oem: 'All' }
      : {
          name: '',
          user_group: '',
          businessType: '',
          oem: '',
          load: '',
          rateModel: 'kilowatt',
          plu: '',
          combo: false,
          tariffs: [
            {
              tariff: '',
              minChargingLimit: '',
              maxChargingLimit: '',
              PLU: '',
              PLUNameEN: '',
              PLUNameAR: '',
              type: '',
            },
          ],
          schedule: [
            {
              price_per_unit: '',
              from: '',
              to: '',
            },
          ],
        };

  const chargeSpotList = [
    { label: 'All', value: 'All' },
    ..._.map(allOems, (oemItem) => {
      return { label: oemItem.name, value: oemItem.id };
    }),
  ];

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${tariffId ? 'Edit' : 'Add New'} Pricing Model`} backTo="/tariffs" />
        <div className="page-content-wrapper scrollable">
          <div className="pricing-form-page-main">
            <Card className="pricing-form-card">
              <div className="pricing-form__block">
                <Formik
                  enableReinitialize={!!tariffId}
                  initialValues={initialValues}
                  validationSchema={TariffSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    const addData = _.omit(values, ['businessType', 'rateModel']);
                    const addTariffData = { ...addData, ...(!values.combo && { tariffs: '' }) };

                    const tariffData = {
                      ...values,
                      schedule: _.map(values.schedule, (item) => _.omit(item, ['_id'])),
                    };
                    const editData = _.omit(tariffData, ['tenant', 'rateModel']);
                    const editTariffData = {
                      ...editData,
                      tariffs: _.map(values.tariffs, (item) => _.omit(item, ['_id'])),
                      ...(!values.combo && { tariffs: '' }),
                    };
                    if (tariffId) {
                      if (values.oem === 'All') {
                        const oemData = { ...editTariffData, oem: '' };
                        updateTariff(oemData);
                      } else {
                        updateTariff(editTariffData);
                      }
                    } else {
                      if (values.oem === 'All') {
                        const oemData = { ...addTariffData, oem: '' };
                        addTariff(oemData);
                      } else {
                        addTariff(addTariffData);
                      }
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, handleSubmit, handleChange, errors, touched, setFieldValue, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={6}>
                          <TextInput
                            isRequired
                            label="Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name ? errors.name : null}
                          />
                        </Col>
                        <Col lg={6}>
                          <Select
                            label="User Group"
                            placeholder="Select user group"
                            options={_.map(allUserGroup, (item) => {
                              return { label: item.name, value: item.id };
                            })}
                            onMenuScrollDown={true}
                            getDataOnScrollDown={getAllUserGroup}
                            page={userGroupPage}
                            totalPage={userGroupTotalPage}
                            name="user_group"
                            value={values.user_group}
                            onChange={(val) => setFieldValue(`user_group`, val === null ? '' : val)}
                          />
                        </Col>
                        <Col lg={6}>
                          <Select
                            label="Business Type"
                            options={[
                              { label: 'Public', value: 'Public' },
                              { label: 'Private', value: 'Private' },
                              { label: 'Hybrid', value: 'Hybrid' },
                            ]}
                            placeholder="Select Type"
                            name="businessType"
                            value={values.businessType}
                            onChange={(val) => setFieldValue(`businessType`, val)}
                          />
                        </Col>
                        <Col lg={6}>
                          <Select
                            label="Chargespot Model"
                            placeholder="Select Chargespot Model"
                            // options={_.map(allOems, (oemItem) => {
                            //   return { label: oemItem.name, value: oemItem.id };
                            // })}
                            options={chargeSpotList}
                            onMenuScrollDown={true}
                            getDataOnScrollDown={getAllOem}
                            page={chargeSpotPage}
                            totalPage={chargeSpotTotalPage}
                            name="oem"
                            value={values.oem}
                            onChange={(val) => setFieldValue(`oem`, val)}
                          />
                        </Col>
                        <Col lg={6}>
                          <TextInput
                            type="number"
                            post_text="kWh"
                            label="Load/Type"
                            name="load"
                            value={values.load}
                            onChange={(e) => setFieldValue('load', e.target.value)}
                          />
                        </Col>
                        <Col lg={6}>
                          <TextInput label="PLU" name="plu" value={values.plu} onChange={handleChange} />
                        </Col>
                        <Col lg={6} md={12}>
                          <Select
                            isRequired
                            label="Rate Model"
                            options={[
                              { label: 'Time', value: 'minutes' },
                              { label: 'Unit', value: 'kilowatt' },
                            ]}
                            placeholder="Rate model"
                            name="rateModel"
                            value={values.rateModel}
                            onChange={(val) => setFieldValue(`rateModel`, val)}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <div className="combo-input__block">
                            <label htmlFor="combo" className="combo--label">
                              Combo :
                            </label>
                            <CheckBox name="combo" checked={values.combo} onChange={(e) => setFieldValue('combo', e.target.checked)} />
                          </div>
                        </Col>
                        {values.combo && (
                          <div className="pricing-form-page__main">
                            <div className="schedule-block__field-inputs">
                              <FieldArray
                                name="tariffs"
                                render={(arrayHelpers) => (
                                  <>
                                    <Row>
                                      {_.map(values.tariffs, (field, index) => (
                                        <>
                                          <Col lg={12} key={`tariffs-${index}`}>
                                            <Row>
                                              <Col lg={4}>
                                                <TextInput
                                                  type="number"
                                                  label="Tariff"
                                                  name="tariff"
                                                  value={field.tariff}
                                                  onChange={(e) => setFieldValue(`tariffs[${index}].tariff`, _.toNumber(e.target.value))}
                                                />
                                              </Col>
                                              <Col lg={4}>
                                                <TextInput
                                                  type="number"
                                                  label="Min Charging Limit"
                                                  name="minChargingLimit"
                                                  value={field.minChargingLimit}
                                                  onChange={(e) => setFieldValue(`tariffs[${index}].minChargingLimit`, _.toNumber(e.target.value))}
                                                />
                                              </Col>
                                              <Col lg={4}>
                                                <TextInput
                                                  type="number"
                                                  label="Max Charging Limit"
                                                  name="maxChargingLimit"
                                                  value={field.maxChargingLimit}
                                                  onChange={(e) => setFieldValue(`tariffs[${index}].maxChargingLimit`, _.toNumber(e.target.value))}
                                                />
                                              </Col>
                                              <Col lg={4}>
                                                <TextInput
                                                  label="PLU"
                                                  name="PLU"
                                                  placeholder="PLU"
                                                  value={field.PLU || ''}
                                                  onChange={(e) => setFieldValue(`tariffs[${index}].PLU`, e.target.value)}
                                                />
                                              </Col>
                                              <Col lg={4}>
                                                <TextInput
                                                  label="PLU Name EN"
                                                  name="PLUNameEN"
                                                  placeholder="PLU Name EN"
                                                  value={field.PLUNameEN || ''}
                                                  onChange={(e) => setFieldValue(`tariffs[${index}].PLUNameEN`, e.target.value)}
                                                />
                                              </Col>
                                              <Col lg={4}>
                                                <TextInput
                                                  label="PLU Name AR"
                                                  name="PLUNameAR"
                                                  placeholder="PLU Name AR"
                                                  value={field.PLUNameAR || ''}
                                                  onChange={(e) => setFieldValue(`tariffs[${index}].PLUNameAR`, e.target.value)}
                                                />
                                              </Col>
                                              <Col lg={4}>
                                                <Select
                                                  label="Type"
                                                  options={[
                                                    { label: 'Fast', value: 'fast' },
                                                    { label: 'Basic', value: 'basic' },
                                                  ]}
                                                  placeholder="Select Type"
                                                  name="type"
                                                  value={field.type || ''}
                                                  onChange={(e) => {
                                                    setFieldValue(`tariffs[${index}].type`, e);
                                                  }}
                                                />
                                              </Col>
                                              <Col xl={1} lg={1} md={1} className="d-flex align-items-center">
                                                {index > 0 && (
                                                  <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                                    <BiTrash size={26} />
                                                  </Button>
                                                )}
                                              </Col>
                                            </Row>
                                          </Col>
                                        </>
                                      ))}
                                    </Row>
                                    <div>
                                      <Button
                                        className="field--btn"
                                        onClick={() =>
                                          arrayHelpers.insert(values.tariffs.length, {
                                            tariff: '',
                                            minChargingLimit: '',
                                            maxChargingLimit: '',
                                            PLU: '',
                                            PLUNameEN: '',
                                            PLUNameAR: '',
                                            type: '',
                                          })
                                        }
                                      >
                                        <BiPlus size={22} className="plus--icon" /> Add
                                      </Button>
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        )}
                      </Row>
                      <SessionHeading text="Schedule" />
                      <div className="pricing-form-page__main">
                        <div className="schedule-block__field-inputs">
                          <FieldArray
                            name="schedule"
                            render={(arrayHelpers) => (
                              <>
                                <Row>
                                  {_.map(values.schedule, (field, index) => (
                                    <>
                                      <Col lg={12} key={`schedule-${index}`}>
                                        <Row>
                                          <Col lg={4}>
                                            <TextInput
                                              isRequired
                                              type="number"
                                              label={values.rateModel === 'minutes' ? 'Price Per Minute' : 'Price Per Unit'}
                                              name="price_per_unit"
                                              value={field.price_per_unit}
                                              onChange={(e) => setFieldValue(`schedule[${index}].price_per_unit`, _.toNumber(e.target.value))}
                                              error={
                                                getIn(errors, `schedule[${index}].price_per_unit`) &&
                                                getIn(touched, `schedule[${index}].price_per_unit`)
                                                  ? getIn(errors, `schedule[${index}].price_per_unit`)
                                                  : null
                                              }
                                            />
                                          </Col>
                                          <Col lg={3} md={3}>
                                            <TextInput
                                              isRequired
                                              type="time"
                                              label="From"
                                              name="from"
                                              value={field.from || ''}
                                              onChange={(e) => setFieldValue(`schedule[${index}].from`, e.target.value)}
                                              error={
                                                getIn(errors, `schedule[${index}].from`) && getIn(touched, `schedule[${index}].from`)
                                                  ? getIn(errors, `schedule[${index}].from`)
                                                  : null
                                              }
                                            />
                                          </Col>
                                          <Col lg={3} md={3}>
                                            <TextInput
                                              isRequired
                                              type="time"
                                              label="To"
                                              name="to"
                                              value={field.to || ''}
                                              onChange={(e) => setFieldValue(`schedule[${index}].to`, e.target.value)}
                                              error={
                                                getIn(errors, `schedule[${index}].to`) && getIn(touched, `schedule[${index}].to`)
                                                  ? getIn(errors, `schedule[${index}].to`)
                                                  : null
                                              }
                                            />
                                          </Col>
                                          <Col xl={1} lg={1} md={1} className="d-flex align-items-center">
                                            {index > 0 && (
                                              <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                                <BiTrash size={26} />
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </>
                                  ))}
                                </Row>
                                <div>
                                  <Button
                                    className="field--btn"
                                    onClick={() =>
                                      arrayHelpers.insert(values.schedule.length, {
                                        price_per_unit: '',
                                        from: '',
                                        to: '',
                                      })
                                    }
                                  >
                                    <BiPlus size={22} className="plus--icon" /> Add
                                  </Button>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="form-btn--block">
                        <Button type="submit" disabled={isSubmitting} className="pricing-form-btn create-btn">
                          Submit
                        </Button>
                        <Button className="pricing-form-btn cancel-btn" onClick={() => history.push(`/tariffs`)}>
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default PricingForm;
