import { post, downloadFile } from 'utils/api';

export const stationUnitConsumption = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/unitconsumption?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/unitconsumption?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/unitconsumption?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/unitconsumption?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/unitconsumption?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/unitconsumption?sortBy=${sortBy}`, data);
  } else if (data.is_rfid_based_booking) {
    if (data.is_rfid_based_booking === 'OTP') {
      delete data['page'];
      delete data['is_rfid_based_booking'];
      return post(`/report/unitconsumption?page=${parseInt(page)}&is_rfid_based_booking=${false}`, data);
    }
    if (data.is_rfid_based_booking === 'RFID') {
      delete data['page'];
      delete data['is_rfid_based_booking'];
      return post(`/report/unitconsumption?page=${parseInt(page)}&is_rfid_based_booking=${true}`, data);
    }
  } else {
    delete data['page'];
    return post(`/report/unitconsumption?page=${parseInt(page)}`, data);
  }
};

export const b2bStationChargingHistory = (data) => {
  return post('/charger_bookings/charginghistory', data);
};

export const b2cStationChargingHistory = (data) => {
  return post('/charger_bookings/charginghistory', data);
};

export const jsonRequestResponseReport = (data) => {
  return post('/charger_logs/report', data);
};

export const walletTransactionReport = (data) => {
  return post('/wallets/report', data);
};

export const moneyTransactionPgReport = (data) => {
  return post('/payments/reports', data);
};

export const appUserDetailsList = (data) => {
  return post('/users/appreports', data);
};

export const mostVehicleChargedList = (data) => {
  return post('/charger_bookings/vehiclescharged', data);
};

export const bookingHistory = (data) => {
  return post('/charger_bookings/charginghistory', data);
};

export const userChargingHistory = (data) => {
  return post('/charger_bookings/charginghistory', data);
};

export const stationChargingSummary = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/chargingsummary?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/chargingsummary?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/chargingsummary?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/chargingsummary?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/chargingsummary?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/chargingsummary?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/chargingsummary?page=${parseInt(page)}`, data);
  }
};

export const bookingSummaryReport = (data) => {
  return post('/charger_bookings/summary', data);
};

export const downloadStationChargingSummary = (data) => {
  return downloadFile(`/report/chargingsummary`, data);
};

export const downloadBookingHistory = (data) => {
  return downloadFile(`/charger_bookings/charginghistory`, data);
};

export const downloadUserChargingHistory = (data) => {
  return downloadFile(`/charger_bookings/charginghistory`, data);
};

export const downloadMoneyTransactionPGReport = (data) => {
  return downloadFile('/payments/reports', data);
};

export const downloadWalletTransactionReport = (data) => {
  return downloadFile('/wallets/report', data);
};

export const downloadB2CStationChargingHistory = (data) => {
  return downloadFile('/charger_bookings/charginghistory', data);
};

export const downloadStationUnitConsumption = (data) => {
  return downloadFile('/report/unitconsumption', data);
};

export const accessReport = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/accessreport?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/accessreport?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/accessreport?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/accessreport?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/accessreport?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/accessreport?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/accessreport?page=${parseInt(page)}`, data);
  }
};

export const downloadAccessReport = (data) => {
  return downloadFile('/report/accessreport', data);
};

export const dataReport = {
  stationUnitConsumption,
  b2bStationChargingHistory,
  b2cStationChargingHistory,
  jsonRequestResponseReport,
  walletTransactionReport,
  moneyTransactionPgReport,
  appUserDetailsList,
  bookingHistory,
  mostVehicleChargedList,
  userChargingHistory,
  stationChargingSummary,
  bookingSummaryReport,
  downloadStationChargingSummary,
  downloadBookingHistory,
  downloadUserChargingHistory,
  downloadMoneyTransactionPGReport,
  downloadWalletTransactionReport,
  downloadB2CStationChargingHistory,
  downloadStationUnitConsumption,
  downloadAccessReport,
  accessReport,
};
