import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { Col, Row } from 'react-bootstrap';
import { BsGraphUp } from 'react-icons/bs';
import { MdOutlinePeopleAlt, MdDataUsage } from 'react-icons/md';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import { BsDot } from 'react-icons/bs';
import Avatar from 'components/inputs/Avatar';
import DummyImage from 'assets/images/icons/dummyImage.png';
import { RiArrowUpSFill, RiArrowDownSFill, RiFilter3Fill } from 'react-icons/ri';
import { GET_DASHBOARD_DATA } from 'actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import chart from 'assets/images/chart.jpg';
import donutChart from 'assets/images/donutChart.jpg';
import barChart from 'assets/images/barChart.jpg';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import { TENANT_ADMIN, SUPER_ADMIN, ADMIN, CPO, DOCO } from 'components/common/constant';
import { AiFillCar } from 'react-icons/ai';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { roundOfDigit } from 'components/common/utils';
import { GiCharging } from 'react-icons/gi';
import { BsGraphDown } from 'react-icons/bs';
import { Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import SearchBox from 'components/general/SearchBox';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { BiSort } from 'react-icons/bi';
import ReactPagination from 'components/general/ReactPagination';
import { CHARGER_STATUS } from 'actions/chargerStatus';
import { startsWith } from 'components/common/utils';

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [allChargingStationData, setAllChargingStationData] = useState([]);

  const chargerStatusList = useSelector((state) => state.chargerStatus.chargerStatus);
  const isLoader = useSelector((state) => state.chargerStatus.isLoading);
  const page = useSelector((state) => state.chargerStatus.page);
  const totalData = useSelector((state) => state.chargerStatus.total);
  const limit = useSelector((state) => state.chargerStatus.limit);
  const totalPages = useSelector((state) => state.chargerStatus.totalPages);
  const dashboardList = useSelector((state) => state.dashboard.dashboard);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserRole = _.get(profileData, 'role', '');
  const saasClientTotal = _.get(
    _.find(_.get(dashboardList, 'saasClients', []), (item) => _.has(item, 'total')),
    'total',
    0
  );
  const currentUnits = _.get(profileData, 'units', '');

  const getDashboardData = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_DATA });
  }, []);

  const chargerStatus = useCallback((value) => {
    if (searchText) {
      const chargerStatusData = {
        ...value,
        search: searchText,
      };
      dispatch({
        type: CHARGER_STATUS,
        payload: chargerStatusData,
      });
    } else {
      const data = {
        ...value,
        limit: 20,
      };
      dispatch({
        type: CHARGER_STATUS,
        payload: data,
      });
    }
  }, []);

  useEffect(() => {
    setAllChargingStationData(
      _.filter(
        chargerStatusList,
        (item) =>
          startsWith(item.name, searchText) ||
          startsWith(item.city, searchText) ||
          startsWith([..._.map(item.chargers, (data) => _.get(data, 'charger_id', ''))].toString(), searchText) ||
          startsWith([..._.map(item.chargers, (data) => _.get(data, 'oem.name', ''))].toString(), searchText)
      )
    );
  }, [searchText, chargerStatusList]);

  useEffect(() => {
    setAllChargingStationData(chargerStatusList);
  }, [chargerStatusList]);

  const getSortByItem = useCallback((name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      chargerStatus(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      chargerStatus(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      chargerStatus(data);
    }
  }, []);

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (searchText) {
        const data = {
          page: page.selected + 1,
        };
        chargerStatus(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        chargerStatus(data);
      }
    },
    [sortByItem, searchText]
  );

  useEffect(() => {
    getDashboardData();
    chargerStatus();
  }, []);

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="Dashboard" />
        <div className="page-content-wrapper scrollable">
          <div className="dashboard-details__main">
            <div className="dashboard-details--inner">
              <div className="dashboard-details__block">
                <Row>
                  {currentUnits === 'amount' ? (
                    <Col xl={4} md={6}>
                      <DashboardCard
                        title={
                          _.get(profileData, 'aed_consumed_limit') === 0
                            ? 'No Limit'
                            : `${roundOfDigit(_.get(profileData, 'aed_consumed_limit', 0), 2)} AED`
                        }
                        icon={<BsGraphUp color="#3C7CDD" className="icon--color" />}
                        subTitle={'Assigned Limit'}
                      />
                    </Col>
                  ) : (
                    <Col xl={3} md={6}>
                      <DashboardCard
                        title={
                          _.get(profileData, 'unit_consumed_limit') === 0
                            ? 'No Limit'
                            : `${roundOfDigit(_.get(profileData, 'unit_consumed_limit', 0), 2)} kW`
                        }
                        icon={<BsGraphUp color="#3C7CDD" className="icon--color" />}
                        subTitle={'Assigned Limit'}
                      />
                    </Col>
                  )}
                  {currentUnits === 'amount' ? (
                    <Col xl={4} md={6}>
                      <DashboardCard
                        // title={`$ ${_.round(_.get(profileData, 'totalRevenue', ''))}`}
                        title={
                          !_.get(profileData, 'aed_amount_used') ? 'No Limit' : `${roundOfDigit(_.get(profileData, 'aed_amount_used', ''), 2)} AED`
                        }
                        metaIcon={<MdDataUsage color="#BE210B" className="icon-inner-color" />}
                        subTitle={'Used Limit'}
                      />
                    </Col>
                  ) : (
                    <Col xl={3} md={6}>
                      <DashboardCard
                        // title={`$ ${_.round(_.get(profileData, 'totalRevenue', ''))}`}
                        title={
                          !_.get(profileData, 'unit_consumed_till_now')
                            ? 'No Limit'
                            : `${roundOfDigit(_.get(profileData, 'unit_consumed_till_now', ''), 2)} kW`
                        }
                        metaIcon={<MdDataUsage color="#BE210B" className="icon-inner-color" />}
                        subTitle={'Used Limit'}
                      />
                    </Col>
                  )}
                  {currentUnits === 'amount' ? (
                    <Col xl={4} md={6}>
                      <DashboardCard
                        title={
                          _.get(profileData, 'aed_consumed_limit') === 0
                            ? 'No Limit'
                            : `${roundOfDigit(_.get(profileData, 'aed_consumed_limit', 0) - _.get(profileData, 'aed_amount_used', 0), 2)} AED`
                        }
                        icon={<BsGraphDown color="#3C7CDD" className="icon--color" />}
                        subTitle={'Remaining Limit'}
                      />
                    </Col>
                  ) : (
                    <Col xl={3} md={6}>
                      <DashboardCard
                        title={
                          _.get(profileData, 'unit_consumed_limit') === 0
                            ? 'No Limit'
                            : `${roundOfDigit(_.get(profileData, 'unit_consumed_limit', 0) - _.get(profileData, 'unit_consumed_till_now', 0), 2)} kW`
                        }
                        icon={<BsGraphDown color="#3C7CDD" className="icon--color" />}
                        subTitle={'Remaining Limit'}
                      />
                    </Col>
                  )}
                  {currentUnits === 'amount' ? null : (
                    <Col xl={3} md={6}>
                      <DashboardCard
                        title={`${roundOfDigit(_.get(profileData, 'unit_consumed_till_now', 0), 2)} kW`}
                        metaIcon={<GiCharging color="#BE210B" className="icon-inner-color" />}
                        subTitle={'Unit Consumed'}
                      />
                    </Col>
                  )}
                </Row>
              </div>
              <div className="dashboard-table__main">
                <Row>
                  <Col xl={currentUserRole === ADMIN ? 12 : 8} lg={12} md={12}>
                    <Row>
                      {(currentUserRole === TENANT_ADMIN || currentUserRole === SUPER_ADMIN || currentUserRole === ADMIN) && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-energySold_chart_main">
                              <div className="dashboard-energySold_chart_inner">
                                <div className="dashboard-energySold_chart_block">
                                  <div className="dashboard-energySold_name">Energy Sold</div>
                                  <div className="dashboard-energySold__btn">
                                    <Button className="dashboard-energySold-inner-btn">Today : 1325 kWh</Button>
                                  </div>
                                </div>
                                <div className="dashboard-energySold_chart_content">
                                  <span className="dashboard-energySold__days">Weekly</span>
                                  <span className="dashboard-energySold_days">Monthly</span>
                                  <span className="dashboard-energySold_line" />
                                  <div className="dashboard-energySold_filter_icon">
                                    <RiFilter3Fill />
                                    <span className="dashboard-energySold_filter_name">Filters</span>
                                  </div>
                                </div>
                              </div>
                              <div className="dashboard-energySold_chart-img">
                                <div className="energySold_chart-img">
                                  <img src={chart} className="energySold_chart" alt={''} />
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {(currentUserRole === TENANT_ADMIN || currentUserRole === SUPER_ADMIN) && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-bookingCount_chart_main">
                              <div className="dashboard-bookingCount_chart_inner">
                                <div className="dashboard-bookingCount_chart_block">
                                  <div className="dashboard-bookingCount_name">Booking Count</div>
                                </div>
                                <div className="dashboard-bookingCount_chart_content">
                                  <span className="dashboard-bookingCount__days">Weekly</span>
                                  <span className="dashboard-bookingCount_days">Monthly</span>
                                </div>
                              </div>
                              <div className="dashboard-bookingCount_chart-img">
                                <div className="bookingCount_chart-img">
                                  <img src={barChart} alt="" className="bookingCount_chart" />
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {(currentUserRole === TENANT_ADMIN ||
                        currentUserRole === SUPER_ADMIN ||
                        currentUserRole === ADMIN ||
                        currentUserRole === CPO) && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-realTime_event_main">
                              <div className="dashboard-realTime_event_table">
                                <div className="realTime_event-header_name">
                                  <span className="mb-3">Realtime events</span>
                                  <div className="dashboard-action--block">
                                    <a className="dashboard-action--btn" onClick={() => history.push('/notification')}>
                                      See all
                                    </a>
                                  </div>
                                </div>
                                <div className="realTime_event-table scrollable">
                                  {_.map(_.get(dashboardList, 'realTimeEvents', []), (data, index) => {
                                    return (
                                      <TableItem
                                        key={`realTimeEvents-${index}`}
                                        icon={<BsDot />}
                                        date={moment(data.createdAt).format('DD MMM YYYY - hh:mm A')}
                                        text={_.get(data, 'message', '')}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xl={4} lg={12} md={12}>
                    <Row>
                      {(currentUserRole === TENANT_ADMIN || currentUserRole === SUPER_ADMIN) && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-charger-chart_main">
                              <div className="dashboard-charger-chart_inner">
                                <div className="dashboard-charger-chart_name">Types of charger</div>
                                <div className="charger-chart_img_block">
                                  <div className="charger-chart_img">
                                    <img src={donutChart} className="charger_chart" alt={''} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {currentUserRole === SUPER_ADMIN && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard__sassClient__main">
                              <div className="dashboard__saasClient_card">
                                <DashboardCard
                                  title={saasClientTotal}
                                  icon={<MdOutlinePeopleAlt color="#3C7CDD" className="icon--color" />}
                                  subTitle={'Total Saas Client'}
                                />
                              </div>
                              <div className="dashboard_inner_saasClient">
                                <div className="dashboard__top_name">Top 5</div>
                                <SaasClient imgSrc={DummyImage} text={'Clair Stanton'} number={1} icon={<RiArrowUpSFill color="#14AA7E" />} />
                                <SaasClient imgSrc={DummyImage} text={'Joseph Ruffin'} number={2} icon={<RiArrowUpSFill color="#14AA7E" />} />
                                <SaasClient imgSrc={DummyImage} text={'Lori Taylor'} number={3} icon={<RiArrowDownSFill color="#BE210B" />} />
                                <SaasClient imgSrc={DummyImage} text={'Colleen Burke'} number={4} icon={<RiArrowUpSFill color="#14AA7E" />} />
                                <SaasClient imgSrc={DummyImage} text={'Phyllis Thomas'} number={5} icon={<RiArrowDownSFill color="#BE210B" />} />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {(currentUserRole === TENANT_ADMIN || currentUserRole === CPO) && (
                        <Col md={12}>
                          <Card className="dashboard-recent--main">
                            <div className="dashboard-recent--inner">
                              <div className="dashboard-recent--block">
                                <span className="dashboard-recent--name">Recent Charges</span>
                                <div className="dashboard-recent--block_content">
                                  <a>See all</a>
                                </div>
                              </div>
                              <div className="dashboard-recent--table_block">
                                <RecentTable
                                  text={'Clair Stanton'}
                                  imgSrc={DummyImage}
                                  subText={'Tesla Model S'}
                                  icon={<AiFillCar />}
                                  date={'23 Dec.'}
                                  time={'08:12 AM'}
                                />
                                <RecentTable
                                  text={'Clair Stanton'}
                                  imgSrc={DummyImage}
                                  subText={'Tesla Model S'}
                                  icon={<AiFillCar />}
                                  date={'23 Dec.'}
                                  time={'08:12 AM'}
                                />
                                <RecentTable
                                  text={'Clair Stanton'}
                                  imgSrc={DummyImage}
                                  subText={'Tesla Model S'}
                                  icon={<AiFillCar />}
                                  date={'23 Dec.'}
                                  time={'08:12 AM'}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {currentUserRole === CPO && (
                        <Col md={12}>
                          <Card className="dashboard-recent--main">
                            <div className="dashboard-recent--inner">
                              <div className="dashboard-recent--block">
                                <span className="dashboard-recent--name">Recent Charges</span>
                                <div className="dashboard-recent--block_content">
                                  <a>See all</a>
                                </div>
                              </div>
                              <div className="dashboard-recent--table_block">
                                <RecentTable
                                  text={'Clair Stanton'}
                                  imgSrc={DummyImage}
                                  subText={'Tesla Model S'}
                                  icon={<AiFillCar />}
                                  date={'23 Dec.'}
                                  time={'08:12 AM'}
                                />
                                <RecentTable
                                  text={'Clair Stanton'}
                                  imgSrc={DummyImage}
                                  subText={'Tesla Model S'}
                                  icon={<AiFillCar />}
                                  date={'23 Dec.'}
                                  time={'08:12 AM'}
                                />
                                <RecentTable
                                  text={'Clair Stanton'}
                                  imgSrc={DummyImage}
                                  subText={'Tesla Model S'}
                                  icon={<AiFillCar />}
                                  date={'23 Dec.'}
                                  time={'08:12 AM'}
                                />
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {(currentUserRole === TENANT_ADMIN || currentUserRole === SUPER_ADMIN) && (
                        <Col md={12}>
                          <Card className="dashboard-recent--main">
                            <div className="dashboard-oem-breakDown-chart_main">
                              <div className="dashboard-oem-breakDown-chart_inner">
                                <div className="dashboard-oem-breakDown-chart_name">OEM Breakdown</div>
                                <div className="oem-breakDown_chart-img_block">
                                  <div className="oem-breakDown_chart-img">
                                    <img src={donutChart} className="oem-breakDown_chart" alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  {currentUserRole === DOCO && (
                    <Col md={12}>
                      <Card className="dashboard-recent--main">
                        <div className="dashboard-oem-breakDown-chart_name">Charger Status</div>
                        <div>&nbsp;</div>
                        <div className="data-report__inner">
                          <div className="data-report__box">
                            <SearchBox value={searchText} preIcon={<BsSearch />} onChange={(e) => setSearchText(e.target.value)} />
                          </div>
                          <div className="data-report__table">
                            <div className="table-responsive">
                              <table className="record-list-table" id="table-to-xls">
                                <thead>
                                  <tr>
                                    <th>
                                      <div className="sorting">
                                        <span>Station Name</span>
                                        <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                          <BiSort size={15} />
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="sorting">
                                        <span>OCPP ID</span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="sorting">
                                        <span>Connectors</span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="sorting">
                                        <span>Status</span>
                                        <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                                          <BiSort size={15} />
                                        </span>
                                      </div>
                                    </th>
                                    <th>
                                      <div className="sorting">
                                        <span>Last Updated</span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isLoader ? (
                                    <tr>
                                      <td colSpan={11} className="border-0">
                                        <div className="loader--block">
                                          <Spinner as="span" animation="border" size="sm" role="status" />
                                          <span className="ps-2">Loading...</span>
                                        </div>
                                      </td>
                                    </tr>
                                  ) : { searchText } && _.isEmpty(allChargingStationData) ? (
                                    <tr>
                                      <td colSpan={6} className="border-0">
                                        <div className="empty-data-block">No Charger Status Found</div>
                                      </td>
                                    </tr>
                                  ) : (
                                    !(isLoader || _.isUndefined(isLoader)) &&
                                    _.map(allChargingStationData, (item0, index0) => {
                                      if (_.get(item0, 'chargers').length > 0) {
                                        return _.map(_.get(item0, 'chargers'), (item1, index1) => {
                                          const globalIndex = parseInt('' + index0 + index1);
                                          const serial_num = limit * (page - 1) + index1;
                                          return (
                                            <React.Fragment key={`charger-status-${serial_num}`}>
                                              <tr>
                                                <td className="station-link">{_.get(item0, 'name', '')}</td>
                                                <td className="charger-link">{_.get(item1, 'charger_id', '')}</td>
                                                <td>
                                                  <div className="connector-wrapper">
                                                    {_.map(_.get(item1, 'plugs'), (item2, index2) => {
                                                      const isCharging = _.get(item2, 'status', '') === 'Charging';
                                                      const isPreparing = _.get(item2, 'status', '') === 'Preparing';
                                                      const isFinishing = _.get(item2, 'status', '') === 'Finishing';
                                                      const isAvailable = _.get(item2, 'status', '') === 'Available';

                                                      if (isCharging || isPreparing || isFinishing) {
                                                        return (
                                                          <div key={index2} className="connector-circle charging">
                                                            {_.get(item2, 'name', '')}
                                                          </div>
                                                        );
                                                      } else if (isAvailable) {
                                                        return (
                                                          <div key={index2} className="connector-circle available">
                                                            {_.get(item2, 'name', '')}
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div key={index2} className="connector-circle unavailable">
                                                            {_.get(item2, 'name', '')}
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                </td>
                                                <td>{_.get(item0, 'status', '')}</td>
                                                <td>{moment.utc(_.get(item1, 'updatedAt', '')).local().format('DD/MM/YYYY H:mm:ss')} </td>
                                                <td className="dropdown-link">
                                                  {activeIndex === globalIndex ? (
                                                    <IoMdArrowDropup onClick={() => setActiveIndex(null)} />
                                                  ) : (
                                                    <IoMdArrowDropdown onClick={() => setActiveIndex(globalIndex)} />
                                                  )}
                                                </td>
                                              </tr>
                                              {activeIndex === globalIndex && (
                                                <React.Fragment>
                                                  {/* sub-table-head-row */}
                                                  <tr className="sub-table-head-row" style={{ backgroundColor: '#141f29' }}>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>Chargepoint ID</span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>Connectors</span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>Status</span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>Error Code</span>
                                                      </div>
                                                    </th>
                                                    <th>
                                                      <div className="sorting">
                                                        <span>Error Message</span>
                                                      </div>
                                                    </th>
                                                    <th></th>
                                                  </tr>
                                                  {/* sub-table-body-row */}
                                                  {_.map(_.get(item1, 'plugs'), (item3, index3) => {
                                                    const isCharging = _.get(item3, 'status', '') === 'Charging';
                                                    const isPreparing = _.get(item3, 'status', '') === 'Preparing';
                                                    const isFinishing = _.get(item3, 'status', '') === 'Finishing';
                                                    const isAvailable = _.get(item3, 'status', '') === 'Available';
                                                    const chargerId = _.get(item1, 'charger_id', '');
                                                    const chargerpointId = chargerId.slice(1) + _.get(item3, 'connector_id', '');

                                                    return (
                                                      <tr key={index3} className="sub-table-body-row">
                                                        <td style={{ backgroundColor: '#141f29' }}>{chargerpointId}</td>
                                                        <td style={{ backgroundColor: '#141f29' }}>
                                                          <div className="connector-wrapper">
                                                            {isCharging || isPreparing || isFinishing ? (
                                                              <div className="connector-circle connector-charging">{_.get(item3, 'name', '')}</div>
                                                            ) : isAvailable ? (
                                                              <div className="connector-circle">{_.get(item3, 'name', '')}</div>
                                                            ) : (
                                                              <div className="connector-circle connector-unavailable">{_.get(item3, 'name', '')}</div>
                                                            )}
                                                          </div>
                                                        </td>
                                                        {isCharging || isPreparing || isFinishing ? (
                                                          <td className="charging" style={{ backgroundColor: '#141f29' }}>
                                                            {_.get(item3, 'status', '')}
                                                          </td>
                                                        ) : isAvailable ? (
                                                          <td className="available" style={{ backgroundColor: '#141f29' }}>
                                                            {_.get(item3, 'status', '')}
                                                          </td>
                                                        ) : (
                                                          <td className="unavailable" style={{ backgroundColor: '#141f29' }}>
                                                            {_.get(item3, 'status', '')}
                                                          </td>
                                                        )}
                                                        <td style={{ backgroundColor: '#141f29' }}>{_.get(item3, 'errorCode', '')}</td>
                                                        <td style={{ backgroundColor: '#141f29' }}>{_.get(item3, 'errorMessage', '')}</td>
                                                        <td style={{ backgroundColor: '#141f29' }}></td>
                                                      </tr>
                                                    );
                                                  })}
                                                </React.Fragment>
                                              )}
                                            </React.Fragment>
                                          );
                                        });
                                      }
                                    })
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allChargingStationData) && (
                            <ReactPagination
                              currentPage={page}
                              limit={limit}
                              total={totalData}
                              handlePageClick={(pageVal) => handlePageClick(pageVal)}
                              totalPages={totalPages}
                              marginPagesDisplayed={1}
                            />
                          )}
                        </div>
                      </Card>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

Dashboard.propTypes = {};
export default Dashboard;

//card Component
export const DashboardCard = (props) => {
  const { icon, title, subTitle, metaIcon } = props;

  return (
    <div className="dashboard-card">
      <div className="dashboard-detail-box">
        <div className="dashboard-icon">
          {icon && <div className="session-icon">{icon}</div>}
          {metaIcon && <div className="session-meta-icon">{metaIcon}</div>}
        </div>
        <div className="dashboard__info">
          <div className="title-session">{title}</div>
          <div className="info-text">{subTitle}</div>
        </div>
      </div>
    </div>
  );
};

DashboardCard.propTypes = {
  /** card icon **/
  icon: PropTypes.node,
  metaIcon: PropTypes.node,
  /** Dashboard title **/
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Dashboard sub-title **/
  subTitle: PropTypes.string,
};

//realTime Event table component
export const TableItem = (props) => {
  let { icon, text, date } = props;

  return (
    <div className="realTime-event-item__main">
      <div className="realTime-event-item--inner">
        <div className="realTime-event-icon--block">
          <div className="realTime-event_icon__inner">
            <div className="realTime-event---icon">{icon}</div>
          </div>
        </div>
        <div className="realTime-event-content__main-block">
          <div className="realTime-event-content--inner">
            <div className="realTime-event-details--content-block">
              <span className="realTime-event-timing">{date}</span>
              <div className="realTime-event-status--icon">{icon}</div>
              <div className="realTime-event-text">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TableItem.propTypes = {
  /** tableList dot icon */
  icon: PropTypes.object,
  /** tableList date */
  date: PropTypes.string,
  /** tableList text */
  text: PropTypes.node,
};

//saas client component
export const SaasClient = (props) => {
  let { icon, text, number } = props;
  return (
    <div className="saasClient--item__main">
      <div className="saasClient--item__inner">
        <Avatar className="saasClient_img" />
        <div className="saasClient-text">{text}</div>
      </div>
      <div className="saasClient-content__main-block">
        <div className="saasClient---number">{number}</div>
        <div className="saasClient-details-icon">{icon}</div>
      </div>
    </div>
  );
};
SaasClient.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  number: PropTypes.number,
};

//recentTable component
const RecentTable = (props) => {
  let { icon, text, date, subText, time } = props;
  return (
    <div className="recentTable--item__main">
      <div className="recentTable--item__inner">
        <div className="recentTable--item__inner_block">
          <div className="recentTable--item__img">
            <Avatar className="recentTable_img" />
          </div>
          <div className="recentTable--item_content">
            <div className="recentTable-text">{text}</div>
            <div className="recentTable--item__inner_content">
              <div className="recentTable-details-icon">{icon}</div>
              <span>{subText}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="recentTable-content__block">
        <div className="recentTable---date">{date}</div>
        <div className="recentTable---time">{time}</div>
      </div>
    </div>
  );
};
RecentTable.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  subText: PropTypes.node,
  date: PropTypes.string,
  time: PropTypes.string,
};
