export const SUPER_ADMIN = 'super_admin';
export const TENANT_ADMIN = 'tenant_admin';
export const ADMIN = 'admin';
export const CUSTOMER = 'customer';
export const CPO = 'cpo';
export const FINANCE = 'finance';
export const DOCO = 'doco';
export const SUPPORT = 'support';
export const FLEET_MEMBER = 'fleet_member';

export const CREATE_STATUS_CODE = 201;
export const UPDATE_STATUS_CODE = 200;
export const DELETE_STATUS_CODE = 204;
