import React, { useEffect, useState, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import SessionHeading from 'components/general/SessionHeading';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_CHARGING_STATION, GET_CHARGING_STATION, UPDATE_CHARGING_STATION } from 'actions/chargingStation';
import CheckBox from 'components/inputs/CheckBox';
import RadioButton from 'components/inputs/RadioButton';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { FETCH_AMENITY } from 'actions/amenity';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { FETCH_AREA, FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
import { FETCH_ELECTRICITY_SUPPLIER } from 'actions/electricitySupplier';
import { FETCH_CHARGING_STATION_ACCESS_TYPE } from 'actions/stationAccessType';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const WeekEndOptions = [
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];

export const WeekDayOptions = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  ...WeekEndOptions,
];

const AddStationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Station name is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters.'),
  countryCode: Yup.string().required('Country Code is required'),
  location: Yup.object().shape({
    lat: Yup.number().required(`latitude is required`).max(90, 'Max latitude is 90').typeError('Only number is allow!'),
    lng: Yup.number().required(`longitude is required`).max(90, 'Max longitude is 90').typeError('Only number is allow!'),
  }),
  // contact_person: Yup.object().shape({
  //   name: Yup.string().required('Name is required').strict(true).trim('Space is not allowed'),
  //   phone: Yup.string().required('Phone number is required').strict(true).trim('Space is not allowed'),
  //   phone: Yup.string().required('Phone number is required').strict(true).trim('Space is not allowed'),
  //   email: Yup.string().required('Email is required').strict(true).trim('Space is not allowed'),
  //   address: Yup.string().required('Address is required').strict(true).trim('Space is not allowed'),
  // }),
  access_type: Yup.string().required('Access Type is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  area: Yup.string().required('Area is required'),
  code: Yup.number().min(100, 'Min code number is 100').max(999, 'Max code number is 999').required(`code is required`),
  address: Yup.string().required('Address is required').strict(true).trim('Space is not allowed'),
  pincode: Yup.string().required('PinCode is required'),
  status: Yup.string().required('Status is required'),
  working_hours: Yup.array().of(
    Yup.object().shape({
      from: Yup.string().required('Time is required'),
      to: Yup.string().required('Time is required'),
    })
  ),
});

const EditStationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Station name is required')
    .strict(true)
    .trim('Space is not allowed')
    .max(100, 'Text limit should be less than 100 characters.'),
  countryCode: Yup.string().required('Country Code is required'),
  code: Yup.number().min(100, 'Min code number is 100').max(999, 'Max code number is 999').required(`code is required`),
  location: Yup.object().shape({
    lat: Yup.number().required(`latitude is required`).max(90, 'Max latitude is 90'),
    lng: Yup.number().required(`longitude is required`).max(90, 'Max longitude is 90'),
  }),
  access_type: Yup.string().required('Access Type is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  area: Yup.string().required('Area is required'),
  address: Yup.string().required('Address is required'),
  pincode: Yup.string().required('PinCode is required'),
  status: Yup.string().required('Status is required'),
  // tags: Yup.array().of(Yup.string().required('Tags is required')),
  working_hours: Yup.array().of(
    Yup.object().shape({
      from: Yup.string().required('Time is required'),
      to: Yup.string().required('Time is required'),
    })
  ),
});

const StationForm = () => {
  const dispatch = useDispatch();
  const { stationId } = useParams();

  const [phoneError, setPhoneError] = useState('');
  const [isDraft, setDraft] = useState(false);
  const [currentCityId, setCurrentCityId] = useState('');

  const chargingStationData = useSelector((state) => state.chargingStation.chargingStationDetail);
  const stationAccessTypeList = useSelector((state) => state.stationAccessType.stationAccessTypes);
  const allElectricitySuppliers = useSelector((state) => state.electricitySupplier.electricitySuppliers);
  const allAmenities = useSelector((state) => state.amenity.amenities);
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCity = useSelector((state) => state.city.city);
  const allArea = useSelector((state) => state.area.areas);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityPage = useSelector((state) => state.city.page);
  const areaPage = useSelector((state) => state.area.page);
  const electricitySuppliersPage = useSelector((state) => state.electricitySupplier.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityTotalPage = useSelector((state) => state.city.totalPages);
  const areaTotalPage = useSelector((state) => state.area.totalPages);
  const electricitySuppliersTotalPage = useSelector((state) => state.electricitySupplier.totalPages);

  const token = cookie.get('token');
  const editStation = useCallback((data) => {
    dispatch({ type: UPDATE_CHARGING_STATION, payload: data });
  }, []);

  const getStationDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGING_STATION, payload: id });
  }, []);

  useEffect(() => {
    if (stationId && _.isEmpty(chargingStationData)) {
      getStationDetail(stationId);
    }
    if (stationId && !_.isEmpty(chargingStationData)) {
      getStateByCountry({ country_name: chargingStationData.country });
      getCityByState({ state_name: chargingStationData.state });
      getAreaByCity({ city_name: chargingStationData.city });
    }
  }, [stationId, chargingStationData]);

  useEffect(() => {
    if (stationId && !_.isEmpty(chargingStationData) && !_.isEmpty(allCity)) {
      const getCityInfo = _.find(allCity, { name: chargingStationData.city });
      if (getCityInfo) {
        getAllElectricitySupplierByCity({ city: getCityInfo.id });
      }
    }
  }, [stationId, allCity]);

  const addStation = useCallback((data) => {
    dispatch({ type: CREATE_CHARGING_STATION, payload: data });
  }, []);

  const getAllAmenity = useCallback(() => {
    dispatch({ type: FETCH_AMENITY, payload: {} });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByState = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY, payload: data });
  }, []);

  const getAreaByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_AREA, payload: data });
  }, []);

  const getAllElectricitySupplierByCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_ELECTRICITY_SUPPLIER, payload: data });
  }, []);

  const getStationAccessType = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGING_STATION_ACCESS_TYPE, payload: data });
  }, []);

  useEffect(() => {
    getAllAmenity();
    getAllCountry();
    getStationAccessType();
  }, []);

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name');
    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, [imgUrlData.url]);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const initialValues =
    stationId && !_.isEmpty(chargingStationData)
      ? {
          ...chargingStationData,
          amenity: _.map(chargingStationData.amenity, (item) => item && item.id),
          pincode: chargingStationData.area ? chargingStationData.pincode : '',
          photos: _.get(chargingStationData, 'photos'),
        }
      : {
          name: '',
          access_type: '',
          address: '',
          location: {
            lat: '',
            lng: '',
          },
          countryCode: '',
          working_hours: [{ day: '', from: '', to: '', avaiabilty_type: 'Available' }],
          timing_type: 'daily',
          description: '',
          amenity: [],
          code: '',
          photos: [],
          contact_person: {
            name: '',
            country_code: 971,
            phone: '',
            email: '',
            address: '',
          },
          country: '',
          state: '',
          city: '',
          area: '',
          pincode: '',
          show_on_mobile_app: true,
          status: '',
          // tags: [],
          settings: {
            energy_limits: '',
            DISCOM_provider: '',
            notification: ['Email', 'SMS'],
          },
          is_active: true,
        };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={` ${stationId ? 'Edit' : 'Add '} Charging Station`} backTo="/chargerStatus" />
        <div className="page-content-wrapper scrollable">
          <div className="add-charging-station-page__main">
            <Card className="station-form--card">
              <Formik
                enableReinitialize={!!stationId}
                initialValues={initialValues}
                validationSchema={stationId ? EditStationSchema : AddStationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  values['is_active'] = !isDraft;
                  const stationValues = {
                    ...values,
                    location: { lat: values.location.lat.toString(), lng: values.location.lng.toString() },
                    settings: { ...values.settings, energy_limits: _.get(values, 'settings.energy_limits', '').toString() },
                    countryCode: values.countryCode.toString(),
                    code: values.code.toString(),
                  };
                  if (!stationId && values.contact_person.phone && !isValidPhoneNumber(values.contact_person.phone)) {
                    setPhoneError('Phone number is not valid!');
                  } else {
                    if (stationId) {
                      const stationData = _.omit(stationValues, [
                        'avg_rating',
                        'rating_count',
                        'total_chargers',
                        'energy_consumed',
                        'chargers_in_use',
                        'average_charging_time',
                        'contact_person',
                        'chargers',
                        'tenant',
                        'availableToUsers',
                        'isFavorite',
                        'createdAt',
                        'updatedAt',
                        'contact_country_code',
                        'number_of_sessions',
                        'total_ev_charged'
                      ]);
                      //edit single station Charging
                      editStation(stationData);
                    } else {
                      //add station Charging
                      const countryCode = _.get(parsePhoneNumber(stationValues.contact_person.phone), 'countryCallingCode', '');
                      const phoneNumber = _.get(parsePhoneNumber(stationValues.contact_person.phone), 'nationalNumber', '');
                      const newStationData = {
                        ...stationValues,
                        contact_person: { ...stationValues.contact_person, country_code: countryCode, phone: phoneNumber },
                      };
                      addStation(newStationData);
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, touched, errors, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <SessionHeading text=" Basic Informations" />
                      <div className="station-form--block">
                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              label="Name of Charging Station"
                              placeholder="Charging Station Name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              error={errors.name && touched.name ? errors.name : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label="Charging Station Access Type"
                              options={_.map(stationAccessTypeList, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder="Select Type"
                              name="access_type"
                              value={values.access_type}
                              onChange={(val) => setFieldValue(`access_type`, val)}
                              error={errors.access_type && touched.access_type ? errors.access_type : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              as="textarea"
                              rows="4"
                              inputClass="scrollable"
                              label="Description"
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={12}>
                                <div className="notification-setting__block">
                                  <label htmlFor="notification_allow" className="notification--label">
                                    Notification Allow As:
                                  </label>
                                  <div id="notification_allow" className="notification-setting--inner">
                                    <div className="notification--item">
                                      <CheckBox
                                        id="email"
                                        name="email"
                                        checked={_.includes(_.get(values, 'settings.notification'), 'Email')}
                                        onChange={() => {
                                          if (_.includes(_.get(values, 'settings.notification'), 'Email')) {
                                            const newNotificationData = _.remove(_.get(values, 'settings.notification'), (item) => item !== 'Email');
                                            setFieldValue('settings.notification', newNotificationData);
                                          } else {
                                            setFieldValue('settings.notification', _.concat(_.get(values, 'settings.notification'), 'Email'));
                                          }
                                        }}
                                      >
                                        Email
                                      </CheckBox>
                                    </div>
                                    <div className="notification--item">
                                      <CheckBox
                                        id="sms"
                                        name="sms"
                                        checked={_.includes(_.get(values, 'settings.notification'), 'SMS')}
                                        onChange={() => {
                                          if (_.includes(_.get(values, 'settings.notification'), 'SMS')) {
                                            const newNotificationData = _.remove(_.get(values, 'settings.notification'), (item) => item !== 'SMS');
                                            setFieldValue('settings.notification', newNotificationData);
                                          } else {
                                            setFieldValue('settings.notification', _.concat(_.get(values, 'settings.notification'), 'SMS'));
                                          }
                                        }}
                                      >
                                        SMS
                                      </CheckBox>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="show-on-mobile-field-main--block">
                                  <div className="show-on-mobile-checkbox-input__block">
                                    <label htmlFor="show_on_mobile_app" className="show-on-mobile--label">
                                      Show On Mobile App:
                                    </label>
                                    <CheckBox
                                      name="show_on_mobile_app"
                                      checked={values.show_on_mobile_app}
                                      onChange={(e) => setFieldValue('show_on_mobile_app', e.target.checked)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <div className="amenity-group-block">
                          <Row>
                            <Col lg={6} md={12}>
                              <div className="checkbox-input--group">
                                <label htmlFor="amenities" className="checkbox-input-label">
                                  Surrounding amenities
                                </label>
                                <div id="amenities" className="amenities-list--block">
                                  {_.map(allAmenities, (item, index) => (
                                    <div key={`amenity-item-${index}`} className="amenities-list--item">
                                      <CheckBox
                                        checked={_.includes(values.amenity, item.id)}
                                        onChange={() => {
                                          if (_.includes(values.amenity, item.id)) {
                                            const newAmenityArr = _.filter(values.amenity, (itemVal) => itemVal !== item.id);
                                            setFieldValue('amenity', newAmenityArr);
                                          } else {
                                            setFieldValue('amenity', _.concat(values.amenity, item.id));
                                          }
                                        }}
                                      >
                                        {item.name}
                                      </CheckBox>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                isRequired
                                label="Code"
                                type="number"
                                placeholder="Code"
                                name="code"
                                value={values.code}
                                onChange={handleChange}
                                error={errors.code && touched.code ? errors.code : null}
                              />
                            </Col>
                          </Row>
                        </div>

                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              isRequired
                              label="Address"
                              name="address"
                              value={values.address}
                              onChange={handleChange}
                              error={errors.address && touched.address ? errors.address : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col>
                                <TextInput
                                  isRequired
                                  label="Latitude"
                                  name="location.lat"
                                  // type="number"
                                  value={values.location.lat}
                                  onChange={handleChange}
                                  onWheelCapture={(e) => {
                                    e.target.blur();
                                  }}
                                  error={getIn(errors, 'location.lat') && getIn(touched, 'location.lat') ? getIn(errors, 'location.lat') : null}
                                />
                              </Col>
                              <Col>
                                <TextInput
                                  isRequired
                                  label="Longitude"
                                  name="location.lng"
                                  // type="number"
                                  value={values.location.lng}
                                  onChange={handleChange}
                                  onWheelCapture={(e) => {
                                    e.target.blur();
                                  }}
                                  error={getIn(errors, 'location.lng') && getIn(touched, 'location.lng') ? getIn(errors, 'location.lng') : null}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col>
                                <Select
                                  isRequired
                                  label="Country"
                                  options={allCountries.map((item) => {
                                    return { label: item.name, value: item.name };
                                  })}
                                  placeholder="Select Country"
                                  name="country"
                                  value={values.country}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={getAllCountry}
                                  page={countryPage}
                                  totalPage={countryTotalPage}
                                  onChange={(val) => {
                                    getStateByCountry({ country_name: val });
                                    const currentCountry = _.find(allCountries, { name: val });
                                    setFieldValue(`country`, val);
                                    setFieldValue(`countryCode`, currentCountry.country_code);
                                    setFieldValue(`state`, '');
                                    setFieldValue(`city`, '');
                                    setFieldValue(`area`, '');
                                    setFieldValue(`pincode`, '');
                                    setFieldValue(`settings.DISCOM_provider`, '');
                                  }}
                                  error={errors.country && touched.country ? errors.country : null}
                                />
                              </Col>
                              <Col>
                                <TextInput
                                  isRequired
                                  disabled
                                  label="Country Code"
                                  name="countryCode"
                                  value={values.countryCode}
                                  error={errors.countryCode && touched.countryCode ? errors.countryCode : null}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col>
                                <Select
                                  isRequired
                                  label="State"
                                  options={_.map(allState, (item) => {
                                    return { label: item.name, value: item.name };
                                  })}
                                  placeholder="Select State"
                                  name="state"
                                  value={values.state}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: _.get(values, 'country') })}
                                  page={statePage}
                                  totalPage={stateTotalPage}
                                  onChange={(val) => {
                                    getCityByState({ state_name: val });
                                    const currentState = _.find(allState, { name: val });
                                    setFieldValue(`state`, currentState.name);
                                    setFieldValue(`city`, '');
                                    setFieldValue(`area`, '');
                                    setFieldValue(`pincode`, '');
                                    setFieldValue(`settings.DISCOM_provider`, '');
                                  }}
                                  error={errors.state && touched.state ? errors.state : null}
                                />
                              </Col>
                              <Col>
                                <Select
                                  isRequired
                                  label="City"
                                  options={
                                    values.state &&
                                    _.map(allCity, (item) => {
                                      return { label: item.name, value: item.name };
                                    })
                                  }
                                  placeholder="Select City"
                                  name="city"
                                  value={values.city}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: _.get(values, 'state') })}
                                  page={cityPage}
                                  totalPage={cityTotalPage}
                                  onChange={(val) => {
                                    getAreaByCity({ city_name: val });
                                    const currentCity = _.find(allCity, { name: val });
                                    setCurrentCityId(currentCity.id);
                                    getAllElectricitySupplierByCity({ city: currentCity.id });
                                    setFieldValue('city', currentCity.name);
                                    setFieldValue(`area`, '');
                                    setFieldValue(`pincode`, '');
                                  }}
                                  error={errors.city && touched.city ? errors.city : null}
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col lg={6} md={12}>
                            <Row>
                              <Col>
                                <Select
                                  isRequired
                                  label="Area"
                                  options={
                                    values.city &&
                                    _.map(allArea, (item) => {
                                      return { label: item.name, value: item.name };
                                    })
                                  }
                                  placeholder="Select Area"
                                  name="area"
                                  value={values.area}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={(data) => getAreaByCity({ ...data, city_name: _.get(values, 'city') })}
                                  page={areaPage}
                                  totalPage={areaTotalPage}
                                  onChange={(val) => {
                                    const currentArea = _.find(allArea, { name: val });
                                    setFieldValue('area', currentArea.name);
                                    setFieldValue(`pincode`, _.get(currentArea, 'postal_code', ''));
                                  }}
                                  error={errors.area && touched.area ? errors.area : null}
                                />
                              </Col>
                              <Col>
                                <TextInput
                                  isRequired
                                  disabled
                                  label="Pincode"
                                  name="pincode"
                                  value={values.pincode}
                                  onChange={handleChange}
                                  error={errors.pincode && touched.pincode ? errors.pincode : null}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label="Status"
                              options={[
                                { label: 'Unavailable', value: 'Unavailable' },
                                { label: 'Available', value: 'Available' },
                                { label: 'Under Maintenance', value: 'Under Maintenance' },
                              ]}
                              placeholder="status"
                              name="status"
                              value={values.status}
                              onChange={(val) => setFieldValue(`status`, val)}
                              error={errors.status && touched.status ? errors.status : null}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={12}>
                            <TextInput
                              post_text="kWh"
                              type="number"
                              label="Energy Limit"
                              name="settings.energy_limits"
                              value={values.settings.energy_limits}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              label="DISCOM Provider"
                              placeholder="Select DISCOM Provider"
                              name="settings.DISCOM_provider"
                              options={
                                values.city &&
                                _.map(allElectricitySuppliers, (electricitySupplierItem) => {
                                  return { label: electricitySupplierItem.name, value: electricitySupplierItem.name };
                                })
                              }
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getAllElectricitySupplierByCity({ ...data, city: currentCityId })}
                              page={electricitySuppliersPage}
                              totalPage={electricitySuppliersTotalPage}
                              value={_.get(values, 'settings.DISCOM_provider')}
                              onChange={(val) => {
                                setFieldValue(`settings.DISCOM_provider`, val === null ? '' : val);
                              }}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            {stationId ? (
                              <TextInput
                                type="file"
                                label="Edit Station Image"
                                name="photos"
                                accept="image/*"
                                onChange={(e) => {
                                  handleImage(...e.target.files, 'photos', setFieldValue);
                                }}
                                error={errors.variant && touched.variant ? errors.variant : null}
                                image={values.photos}
                              />
                            ) : (
                              <TextInput
                                type="file"
                                label="Add Station Image"
                                name="photos"
                                accept="image/*"
                                onChange={(e) => {
                                  handleImage(...e.target.files, 'photos', setFieldValue);
                                }}
                                error={errors.variant && touched.variant ? errors.variant : null}
                              />
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>

                    {/*<SessionHeading text="Tag Information" />*/}
                    {/*<div className="station-form--block">*/}
                    {/*  <div className="tag-block__field-inputs">*/}
                    {/*    <FieldArray*/}
                    {/*      name="tags"*/}
                    {/*      render={(arrayHelpers) => (*/}
                    {/*        <>*/}
                    {/*          <Row>*/}
                    {/*            {values.tags.map((tags, index) => (*/}
                    {/*              <>*/}
                    {/*                <Col xl={4} md={6} key={`tags-${index}`}>*/}
                    {/*                  <Row>*/}
                    {/*                    <Col>*/}
                    {/*                      <TextInput*/}
                    {/*                        name="tags"*/}
                    {/*                        value={tags}*/}
                    {/*                        onChange={(e) => setFieldValue(`tags.[${index}]`, e.target.value)}*/}
                    {/*                        error={*/}
                    {/*                          getIn(errors, `tags.[${index}]`) && getIn(touched, `tags.[${index}]`)*/}
                    {/*                            ? getIn(errors, `tags.[${index}]`)*/}
                    {/*                            : null*/}
                    {/*                        }*/}
                    {/*                      />*/}
                    {/*                    </Col>*/}
                    {/*                    <Col xl={1} lg={1} md={1} className="p-0 d-flex align-items-center">*/}
                    {/*                      <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>*/}
                    {/*                        <BiTrash size={26} />*/}
                    {/*                      </Button>*/}
                    {/*                    </Col>*/}
                    {/*                  </Row>*/}
                    {/*                </Col>*/}
                    {/*              </>*/}
                    {/*            ))}*/}
                    {/*          </Row>*/}
                    {/*          <Button className="field--btn" onClick={() => arrayHelpers.insert(values.tags.length)}>*/}
                    {/*            <BiPlus size={22} className="plus--icon" /> Add*/}
                    {/*          </Button>*/}
                    {/*        </>*/}
                    {/*      )}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div>
                      <SessionHeading text="Operational Timings" />
                      <div className="station-form--block">
                        <div className="checkbox-input--group">
                          <div id="timing" className="checkbox--input-items">
                            <Row>
                              <Col xxl={6} xl={8} md={12}>
                                <Row className="m-0">
                                  <Col xl={2} lg={2} md={6} className="p-0">
                                    <RadioButton
                                      checked={values.timing_type === 'daily'}
                                      onChange={() => {
                                        setFieldValue('timing_type', 'daily');
                                        setFieldValue(`working_hours[0].day`, 'daily');
                                      }}
                                    >
                                      Daily
                                    </RadioButton>
                                  </Col>
                                  <Col xl={4} lg={4} md={6} className="p-0">
                                    <RadioButton checked={values.timing_type === 'weekday'} onChange={() => setFieldValue('timing_type', 'weekday')}>
                                      Weekdays only
                                    </RadioButton>
                                  </Col>
                                  <Col xl={4} lg={4} md={6} className="p-0">
                                    <RadioButton
                                      checked={values.timing_type === 'weekends'}
                                      onChange={() => setFieldValue('timing_type', 'weekends')}
                                    >
                                      Weekends only
                                    </RadioButton>
                                  </Col>
                                  <Col xl={2} lg={2} md={6} className="p-0">
                                    <RadioButton checked={values.timing_type === 'custom'} onChange={() => setFieldValue('timing_type', 'custom')}>
                                      Custom
                                    </RadioButton>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        {values.timing_type && (
                          <div className="time-block__field-inputs">
                            <FieldArray
                              name="working_hours"
                              render={(arrayHelpers) => (
                                <>
                                  {_.map(values.working_hours, (field, index) => (
                                    <>
                                      <Row key={`station-timimg-${index}`}>
                                        {values.timing_type !== 'daily' && (
                                          <Col lg={5} md={4}>
                                            <Select
                                              label="Day"
                                              name="day"
                                              placeholder="Select Day"
                                              value={field.day || ''}
                                              options={values.timing_type === 'weekends' ? WeekEndOptions : WeekDayOptions}
                                              onChange={(val) => setFieldValue(`working_hours[${index}].day`, val)}
                                            />
                                          </Col>
                                        )}
                                        <Col lg={6} md={7}>
                                          <Row>
                                            <Col>
                                              <TextInput
                                                isRequired
                                                type="time"
                                                label="Hour from"
                                                name="from"
                                                value={field.from}
                                                onChange={(e) => setFieldValue(`working_hours[${index}].from`, e.target.value)}
                                                error={
                                                  getIn(errors, `working_hours[${index}].from`) && getIn(touched, `working_hours[${index}].from`)
                                                    ? getIn(errors, `working_hours[${index}].from`)
                                                    : null
                                                }
                                              />
                                            </Col>
                                            <Col>
                                              <TextInput
                                                isRequired
                                                type="time"
                                                label="Hour to"
                                                name="to"
                                                value={field.to}
                                                onChange={(e) => setFieldValue(`working_hours[${index}].to`, e.target.value)}
                                                error={
                                                  getIn(errors, `working_hours[${index}].to`) && getIn(touched, `working_hours[${index}].to`)
                                                    ? getIn(errors, `working_hours[${index}].to`)
                                                    : null
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md={1} lg={1} sm={1} className="d-flex align-items-center">
                                          {index > 0 && (
                                            <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                              <BiTrash size={26} />
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    </>
                                  ))}
                                  {!(values.timing_type === 'daily') && (
                                    <div>
                                      <Button
                                        className="field--btn"
                                        onClick={() =>
                                          arrayHelpers.insert(values.working_hours.length, {
                                            day: '',
                                            from: moment().format('h:mm a'),
                                            to: moment().format('h:mm a'),
                                            avaiabilty_type: 'Available',
                                          })
                                        }
                                      >
                                        <BiPlus size={22} className="plus--icon" /> Add
                                      </Button>
                                    </div>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {!stationId && (
                      <div>
                        <SessionHeading text="Owner/Contact Person" />
                        <div className="station-form--block">
                          <Row>
                            <Col lg={6} md={12}>
                              <TextInput label="Name" name="contact_person.name" value={values.contact_person.name} onChange={handleChange} />
                            </Col>
                            <Col lg={6} md={12}>
                              <PhoneNumberInput
                                label="Phone number"
                                name="contact_person.phone"
                                value={values.contact_person.phone}
                                onChange={(val) => {
                                  setFieldValue(`contact_person.phone`, val);
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6} md={12}>
                              <TextInput
                                label="Email Address"
                                name="contact_person.email"
                                value={values.contact_person.email}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col lg={6} md={12}>
                              <TextInput
                                label="Address"
                                name="contact_person.address"
                                value={values.contact_person.address}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                    <div className="save-draft-btn--block mt-5">
                      <Button type="submit" className="save-btn draft--btn" disabled={isSubmitting} onClick={() => setDraft(true)}>
                        Save as Draft
                      </Button>

                      <Button type="submit" className="save-btn" disabled={isSubmitting} onClick={() => setDraft(false)}>
                        Save and Publish
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default StationForm;
