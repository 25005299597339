import * as _ from 'lodash';
import moment from 'moment';

export const startsWith = (sourceStr, search_string) => {
  if (!search_string) {
    return true;
  }

  return (sourceStr || '').toLowerCase().indexOf(search_string.toLowerCase()) >= 0;
};

export const millisecondsToHourMinute = (value) => {
  const d = moment.duration(value, 'milliseconds');
  const hours = Math.floor(d.asHours());
  const mins = Math.floor(d.asMinutes()) - hours * 60;
  return hours + ':' + mins;
};

export const roundOfDigit = (value, digit) => {
  return _.round(value, digit);
};
