import React, { useCallback, useEffect, useState } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import { useParams } from 'react-router-dom';
import Card from 'components/inputs/Card';
import { BsDot, BsThreeDots } from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import DropDown from 'components/inputs/DropDown';
import { FaChargingStation } from 'react-icons/fa';
// import ReactStars from 'react-rating-stars-component';
import Button from 'components/inputs/Button';
import General from './General';
import Chargers from './Chargers';
// import Consumption from './Consumption';
// import Reservations from './Reservations';
// import Reviews from './Reviews';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_STATION_STATUS, GET_CHARGING_STATION } from 'actions/chargingStation';
import { DELETE_CHARGING_STATION } from 'actions/chargingStation';
import Switch from 'components/inputs/Switch';
import * as _ from 'lodash';
import DeleteModal from 'components/general/DeleteModal';
import { Modal, Spinner } from 'react-bootstrap';
import { FiEdit2 } from 'react-icons/fi';
import Footer from 'components/general/Footer';
import { SUPPORT } from 'components/common/constant';

const StationDetails = () => {
  const { stationId } = useParams();
  const [currentTab, setCurrentTab] = useState('general');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [checked, setChecked] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.profile.userProfile);
  const chargingStationData = useSelector((state) => state.chargingStation.chargingStationDetail);
  const isLoader = useSelector((state) => state.chargingStation.isLoading);
  // const avgRating = _.round(_.get(chargingStationData, 'avg_rating', 0), 1);
  const currentUserRole = _.get(profileData, 'role', '');

  const handleCloseDeleteModel = () => setShowDeleteModal(false);

  const deleteChargingStation = useCallback((id) => dispatch({ type: DELETE_CHARGING_STATION, payload: id }), []);

  const getStationDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGING_STATION, payload: id });
  }, []);

  useEffect(() => {
    getStationDetail(stationId);
  }, [stationId]);

  const editStationDetail = useCallback(() => {
    const stationData = {
      id: chargingStationData.id,
      is_enabled: chargingStationData.is_enabled !== true,
    };
    dispatch({ type: CHANGE_STATION_STATUS, payload: stationData });
  }, [chargingStationData]);

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Stations and Chargers" backTo={location.state ? `/${location.state?.backTo}` : "/chargerStatus"} />
        <div className="page-content-wrapper scrollable">
          {isLoader ? (
            <div className="station-details__main">
              <div className="loader--block">
                <Spinner as="span" animation="border" size="lg" role="status" />
                <span className="ps-2">Loading...</span>
              </div>
            </div>
          ) : (
          <div className="station-details__main">
            <Card>
              <div className="station-info--box">
                <div className="station-info-content">
                  <div className="station-info__inner">
                    <div className="station-icon--block">
                      <div className="station-icon">
                        <FaChargingStation />
                      </div>
                    </div>
                    <div className="station-info--wrapper">
                      <div className="station-status--block">
                        <span className="info-name">{_.get(chargingStationData, 'name', '')}</span>
                        <div className={'station-status-block--inner'}>
                          {chargingStationData.is_enabled === false && (
                            <div
                              className={`station-status ${
                                _.get(chargingStationData, 'is_enabled', '') === true ? 'status-color-green text--size' : 'status-color-red'
                              }`}
                            >
                              <BsDot className="dot--icon" size={20} />
                              <span className="status-text">Disabled</span>
                            </div>
                          )}
                          <div
                            className={`station-status ${
                              _.get(chargingStationData, 'status', '') === 'Available' ? 'status-color-green' : 'status-color-red'
                            }`}
                          >
                            <BsDot className="dot--icon" size={20} />
                            <span>{_.get(chargingStationData, 'status', '')}</span>
                            {/* <span>Station is {chargingStationData.is_enabled === true ? 'Enable' : 'Disable'}</span> */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="station-info-rating--block">
                        <div className="rating-star-block">
                          {!_.isEmpty(chargingStationData) && (
                            <ReactStars
                              edit={false}
                              a11y={true}
                              count={5}
                              value={avgRating}
                              size={14}
                              isHalf={true}
                              emptyIcon={<BsStar />}
                              halfIcon={<BsStarHalf />}
                              filledIcon={<BsStarFill />}
                              activeColor="#BE210B"
                              color="#BE210B"
                            />
                          )}
                        </div>
                        <span className="rating-count">
                          {avgRating}/5 ({_.get(chargingStationData, 'rating_count', '')})
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
                {currentUserRole !== SUPPORT && <div className="station-dropdown_btn">
                  <DropDown
                    trigger={
                      <div className="dropdown-icon">
                        <BsThreeDots />
                      </div>
                    }
                    popupClass="bottom-left"
                  >
                    <ul>
                      <li className="item">
                        <div onClick={editStationDetail} className="station--switch">
                          <Switch
                            label={'Disable Station'}
                            // onChange={() => setChecked(!checked)}
                            checked={chargingStationData.is_enabled === false}
                            // onClick={editStationDetail}
                          />
                        </div>
                      </li>
                      <li className="item" onClick={() => setShowDeleteModal(true)}>
                        <div className="delete--station">
                          <RiDeleteBin6Line color={'#E12F17'} />
                          <span className="delete-station-text">Delete Station</span>
                        </div>
                      </li>
                      <li className="item" onClick={() => history.push(`/editStation/${chargingStationData.id}`)}>
                        <div className="edit--station">
                          <span>
                            <FiEdit2 color="skyblue" />
                          </span>
                          <span className="edit-station-text">Edit Station</span>
                        </div>
                      </li>
                    </ul>
                  </DropDown>
                </div>}
              </div>
              <div>
                <div className="tab-block">
                  <div className="tab-nav-block">
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'general' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'general')}
                      data-content="tab-general"
                    >
                      General
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'chargers' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'chargers')}
                      data-content="tab-chargers"
                    >
                      Chargers
                    </a>
                    {/* <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'consumption' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'consumption')}
                      data-content="tab-consumption"
                    >
                      Consumption
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'reservations' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'reservations')}
                      data-content="tab-reservations"
                    >
                      Reservations
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'reviews' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'reviews')}
                      data-content="tab-reviews"
                    >
                      Reviews
                    </a> */}
                   {currentUserRole !== SUPPORT && <div className="tab-nav-block__right">
                      <Button onClick={() => history.push(`/addCharger?station=${stationId}`)}>Add Charger</Button>
                    </div>}
                  </div>
                  <div className="tab-content-block">
                    <div className="tab-content__item active" id="station-details-tab">
                      {currentTab === 'general' && <General />}
                      {currentTab === 'chargers' && <Chargers />}
                      {/* {currentTab === 'consumption' && <Consumption />}
                      {currentTab === 'reservations' && <Reservations />}
                      {currentTab === 'reviews' && <Reviews />} */}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          )}
          <Footer />
        </div>
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="station" onClose={handleCloseDeleteModel} onRemove={() => deleteChargingStation(chargingStationData.id)} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};

export default StationDetails;
