import React, { useEffect, useCallback, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import * as _ from 'lodash';
import { CHARGER_STATUS } from 'actions/chargerStatus';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BiSort } from 'react-icons/bi';
//import { IoSearchOutline } from 'react-icons/io5';
import ReactPagination from 'components/general/ReactPagination';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import Select from 'components/inputs/Select';
import { MdRefresh } from 'react-icons/md';
import { Form, Formik } from 'formik';
import OffCanvas from 'components/inputs/OffCanvas';
import { UPDATE_STATUS_CODE, CPO, SUPPORT } from 'components/common/constant';
//import { RiEqualizerFill } from 'react-icons/ri';

const ChargerStatus = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  //const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeStation, setChangeStation] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [allChargingStationData, setAllChargingStationData] = useState([]);

  const chargerStatusList = useSelector((state) => state.chargerStatus.chargerStatus);
  const isLoader = useSelector((state) => state.chargerStatus.isLoading);
  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const page = useSelector((state) => state.chargerStatus.page);
  const totalData = useSelector((state) => state.chargerStatus.total);
  const limit = useSelector((state) => state.chargerStatus.limit);
  const totalPages = useSelector((state) => state.chargerStatus.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserRole = _.get(profileData, 'role', '');

  const chargerStatus = useCallback((value) => {
    if (searchText) {
      const chargerStatusData = {
        ...value,
        search: searchText,
      };
      dispatch({
        type: CHARGER_STATUS,
        payload: chargerStatusData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            onCloseAdvanceFilterPopup();
          }
        },
      });
    } else {
      const data = {
        ...value,
        limit: 20,
      };
      dispatch({
        type: CHARGER_STATUS,
        payload: data,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            onCloseAdvanceFilterPopup();
          }
        },
      });
    }
  }, []);

  /* const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All') {
        const filterData = {
          ...advanceFilterData, //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : ''
        };
        const data = {
          ...filterData,
        };
        chargerStatus(data);
      } else {
        const data = {
          ...advanceFilterData,
        };
        chargerStatus(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        //charging_station: userProfileStations,
      };
      chargerStatus(filterDateData);
    } else {
      const filterDateData = {};
      chargerStatus(filterDateData);
    }
  }, [sortByItem, advanceFilterData]); */

  /* const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  }; */

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const sortedOrder = 'desc';
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
        charging_station: userProfileStations,
      };
      chargerStatus(data);
    } else {
      const sortedOrder = 'desc';
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
      };
      chargerStatus(data);
    }
    getAllStation();
  }, []);

  useEffect(() => {
    if (currentUserRole === CPO || currentUserRole === SUPPORT) {
      const filteredStations = chargerStatusList.filter((v) => userProfileStations.find((station) => station === v.id));
      setAllChargingStationData(filteredStations);
    } else {
      setAllChargingStationData(chargerStatusList);
    }
  }, [chargerStatusList]);

  useEffect(() => {
    if (currentUserRole === CPO || currentUserRole === SUPPORT) {
      const filteredStations = chargerStatusList.filter((v) => userProfileStations.find((station) => station === v.id));
      setAllChargingStationData(
        _.filter(
          filteredStations,
          (item) =>
            startsWith(item.name, searchText) ||
            startsWith(item.city, searchText) ||
            startsWith([..._.map(item.chargers, (data) => _.get(data, 'charger_id', ''))].toString(), searchText) ||
            startsWith([..._.map(item.chargers, (data) => _.get(data, 'oem.name', ''))].toString(), searchText)
        )
      );
    } else {
      setAllChargingStationData(
        _.filter(
          chargerStatusList,
          (item) =>
            startsWith(item.name, searchText) ||
            startsWith(item.city, searchText) ||
            startsWith([..._.map(item.chargers, (data) => _.get(data, 'charger_id', ''))].toString(), searchText) ||
            startsWith([..._.map(item.chargers, (data) => _.get(data, 'oem.name', ''))].toString(), searchText)
        )
      );
    }
  }, [searchText, chargerStatusList]);

  // useEffect(() => {
  //   if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
  //     const sortedOrder = 'desc';
  //     const data = {
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
  //       charging_station: userProfileStations,
  //     };
  //     chargerStatus(data);
  //   } else {
  //     const sortedOrder = 'desc';
  //     const data = {
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
  //     };
  //     chargerStatus(data);
  //   }
  // }, [searchText]);

  useEffect(() => {
    setAllChargingStationData(chargerStatusList);
  }, [chargerStatusList]);

  const getSortByItem = useCallback((name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      chargerStatus(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      chargerStatus(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      chargerStatus(data);
    }
  }, []);

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (advanceFilterData.charging_station === 'All') {
          const filterData = {
            ...advanceFilterData,
            //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : ''
          };
          const data = {
            ...filterData,
            page: page.selected + 1,
          };
          chargerStatus(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
          };
          chargerStatus(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          //charging_station: userProfileStations,
          page: page.selected + 1,
        };
        chargerStatus(filterDateData);
      } else if (searchText) {
        const data = {
          page: page.selected + 1,
        };
        chargerStatus(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        chargerStatus(data);
      }
    },
    [sortByItem, advanceFilterData, searchText]
  );

  //const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  //const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList = allStation;
  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', wallet_type: '', type: '' };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Charger Status" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <SearchBox
                    value={searchText}
                    preIcon={<BsSearch />}
                    //postIcon={<RiEqualizerFill onClick={() => setShowAdvanceFilterPopup(true)} />}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  {/* <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 2 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    <Col xl={1} md={2}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={2} md={4}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <Button className="report-search-button">Advance Filter</Button>
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Station Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>OCPP ID</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connectors</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Status</span>
                              <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Last Updated</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={11} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : {searchText} && _.isEmpty(allChargingStationData) ? (
                          <tr>
                            <td colSpan={6} className="border-0">
                              <div className="empty-data-block">No Charger Status Found</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(allChargingStationData, (item0, index0) => {
                            if (_.get(item0, 'chargers').length > 0) {
                              return _.map(_.get(item0, 'chargers'), (item1, index1) => {
                                const globalIndex = parseInt('' + index0 + index1);
                                const serial_num = limit * (page - 1) + index1;
                                return (
                                  <React.Fragment key={`charger-status-${serial_num}`}>
                                    <tr>
                                      <td
                                        className="station-link"
                                        onClick={() => history.push(`/stations/${_.get(item0, 'id', '')}`, { backTo: 'chargerStatus' })}
                                      >
                                        {_.get(item0, 'name', '')}
                                      </td>
                                      <td
                                        className="charger-link"
                                        onClick={() => history.push(`/charger/${_.get(item1, 'id', '')}`, { backTo: 'chargerStatus' })}
                                      >
                                        {_.get(item1, 'charger_id', '')}
                                      </td>
                                      <td>
                                        <div className="connector-wrapper">
                                          {_.map(_.get(item1, 'plugs'), (item2, index2) => {
                                            const isCharging = _.get(item2, 'status', '') === 'Charging';
                                            const isPreparing = _.get(item2, 'status', '') === 'Preparing';
                                            const isFinishing = _.get(item2, 'status', '') === 'Finishing';
                                            const isAvailable = _.get(item2, 'status', '') === 'Available';

                                            if (isCharging || isPreparing || isFinishing) {
                                              return (
                                                <div key={index2} className="connector-circle charging">
                                                  {_.get(item2, 'name', '')}
                                                </div>
                                              );
                                            } else if (isAvailable) {
                                              return (
                                                <div key={index2} className="connector-circle available">
                                                  {_.get(item2, 'name', '')}
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <div key={index2} className="connector-circle unavailable">
                                                  {_.get(item2, 'name', '')}
                                                </div>
                                              );
                                            }
                                          })}
                                        </div>
                                      </td>
                                      <td>{_.get(item0, 'status', '')}</td>
                                      <td>{moment.utc(_.get(item1, 'updatedAt', '')).local().format('DD/MM/YYYY H:mm:ss')} </td>
                                      <td className="dropdown-link">
                                        {activeIndex === globalIndex ? (
                                          <IoMdArrowDropup onClick={() => setActiveIndex(null)} />
                                        ) : (
                                          <IoMdArrowDropdown onClick={() => setActiveIndex(globalIndex)} />
                                        )}
                                      </td>
                                    </tr>
                                    {activeIndex === globalIndex && (
                                      <React.Fragment>
                                        {/* sub-table-head-row */}
                                        <tr className="sub-table-head-row" style={{ backgroundColor: '#141f29' }}>
                                          <th>
                                            <div className="sorting">
                                              <span>Chargepoint ID</span>
                                            </div>
                                          </th>
                                          <th>
                                            <div className="sorting">
                                              <span>Connectors</span>
                                            </div>
                                          </th>
                                          <th>
                                            <div className="sorting">
                                              <span>Status</span>
                                            </div>
                                          </th>
                                          <th>
                                            <div className="sorting">
                                              <span>Error Code</span>
                                            </div>
                                          </th>
                                          <th>
                                            <div className="sorting">
                                              <span>Error Message</span>
                                            </div>
                                          </th>
                                          <th></th>
                                        </tr>
                                        {/* sub-table-body-row */}
                                        {_.map(_.get(item1, 'plugs'), (item3, index3) => {
                                          const isCharging = _.get(item3, 'status', '') === 'Charging';
                                          const isPreparing = _.get(item3, 'status', '') === 'Preparing';
                                          const isFinishing = _.get(item3, 'status', '') === 'Finishing';
                                          const isAvailable = _.get(item3, 'status', '') === 'Available';
                                          const chargerId = _.get(item1, 'charger_id', '');
                                          const chargerpointId = chargerId.slice(1) + _.get(item3, 'connector_id', '');

                                          return (
                                            <tr key={index3} className="sub-table-body-row">
                                              <td style={{ backgroundColor: '#141f29' }}>{chargerpointId}</td>
                                              <td style={{ backgroundColor: '#141f29' }}>
                                                <div className="connector-wrapper">
                                                  {isCharging || isPreparing || isFinishing ? (
                                                    <div className="connector-circle connector-charging">{_.get(item3, 'name', '')}</div>
                                                  ) : isAvailable ? (
                                                    <div className="connector-circle">{_.get(item3, 'name', '')}</div>
                                                  ) : (
                                                    <div className="connector-circle connector-unavailable">{_.get(item3, 'name', '')}</div>
                                                  )}
                                                </div>
                                              </td>
                                              {isCharging || isPreparing || isFinishing ? (
                                                <td className="charging" style={{ backgroundColor: '#141f29' }}>
                                                  {_.get(item3, 'status', '')}
                                                </td>
                                              ) : isAvailable ? (
                                                <td className="available" style={{ backgroundColor: '#141f29' }}>
                                                  {_.get(item3, 'status', '')}
                                                </td>
                                              ) : (
                                                <td className="unavailable" style={{ backgroundColor: '#141f29' }}>
                                                  {_.get(item3, 'status', '')}
                                                </td>
                                              )}
                                              <td style={{ backgroundColor: '#141f29' }}>{_.get(item3, 'errorCode', '')}</td>
                                              <td style={{ backgroundColor: '#141f29' }}>{_.get(item3, 'errorMessage', '')}</td>
                                              <td style={{ backgroundColor: '#141f29' }}></td>
                                            </tr>
                                          );
                                        })}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                );
                              });
                            }
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allChargingStationData) && currentUserRole !== CPO && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.charging_station && delete values['charging_station'];
              if (values.charging_station === 'All') {
                const walletTransactionReportData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                chargerStatus(walletTransactionReportData);
              } else {
                chargerStatus(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label="Station"
                      options={
                        !_.isEmpty(stationList)
                          ? !changeStation
                            ? !_.isEmpty(stationList) && [
                                { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return { label: station.name, value: station.id };
                                }),
                              ]
                            : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                          : !changeStation
                          ? !_.isEmpty(allStation) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStation, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Station"
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charging_station', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      chargerStatus();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdRefresh size={24} className="reset--icon" />
                    Reset
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default ChargerStatus;
