import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import { ADMIN, SUPER_ADMIN, TENANT_ADMIN, FINANCE, DOCO, CPO, SUPPORT, FLEET_MEMBER } from 'components/common/constant';
import PropTypes from 'prop-types';
import PrivateRoute from 'components/privateRoute/PrivateRoute';
// import Login from 'view/login/index';
import Loginas from 'view/login/loginas';
import Logout from './view/logout/Logout';
import ForgotPassword from 'view/forgotPassword';
import Dashboard from 'view/dashboard/index';
import ChargerStationList from 'view/stations/StationList';
import StationDetails from './view/stations/StationDetails';
import StationForm from 'view/stations/StationForm';
import ChargerDetail from './view/charger/ChargerDetail';
import ChargerForm from 'view/charger/ChargerForm';
import VehicleList from 'view/vehicle/VehicleList';
import VehicleForm from 'view/vehicle/VehicleForm';
import TenantAdminList from './view/tenantAdmin/TenantAdminList';
import TenantAdminDetails from './view/tenantAdmin/TenantAdminDetails';
import TenantForm from 'view/tenant/TenantForm';
import FirmWareUpdate from './view/firmWare/FirmWareUpdate';
import CustomerList from './view/customerManagement/CustomerList';
import ChargerStatus from './view/chargerStatus';
import TariffList from './view/tariffManagement/TariffList';
import BillingInvoice from './view/billingAndInvoice/BillingInvoice';
// import DataReportsList from './view/dataReports/DataReportsList';
import ChargerActivityDetail from 'view/charger/ChargerActivityDetail';
import Setting from 'view/setting/Setting';
import ProfileForm from './view/setting/ProfileForm';
import UserForm from './view/setting/UserForm';
import SubscriptionList from 'view/subscriptions/SubscriptionList';
import CountryList from './view/master/country/CountryList';
import StateList from './view/master/state/StateList';
import CityList from './view/master/city/CityList';
import AreaList from './view/master/area/AreaList';
import MakeList from './view/master/make/MakeList';
import ModelList from './view/master/model/ModelList';
import AmenityList from './view/master/amenity/AmenityList';
import ElectricitySupplierList from './view/master/electricitySupplier/ElectricitySupplierList';
import StationAccessTypeList from './view/master/stationAccessType/StationAccessTypeList';
import OemVendorList from './view/master/oemVendor/OemVendorList';
import FaqList from './view/master/faq/FaqList';
import PrivacyList from './view/master/privacy/PrivacyList';
import ConnectorTypeList from './view/master/connectorType/ConnectorTypeList';
import ChargeSpeedList from 'view/master/chargeSpeed/ChargeSpeedList';
import OemList from './view/master/oem/OemList';
import OemForm from 'view/master/oem/OemForm';
// import FaqsAndPrivacy from 'view/master/faqsAndPrivacy/FaqsAndPrivacy';
import RoleList from './view/master/role/RoleList';
import ChargerStateList from 'view/master/chargerState/ChargerStateList';
import OemErrorCodeList from 'view/master/oemErrorCode/OemErrorCodeList';
import StationUnitConsumption from 'view/dataReports/StationUnitConsumption';
import B2BStationChargingHistory from 'view/dataReports/B2BStationChargingHistory';
import B2CStationChargingHistory from 'view/dataReports/B2CStationChargingHistory';
import JsonRequestResponseReport from 'view/dataReports/JsonRequestResponseReport';
import WalletTransactionReport from 'view/dataReports/WalletTransactionReport';
import AppUserDetailsList from 'view/dataReports/AppUserDetailsList';
import MostVehicleChargedList from 'view/dataReports/MostVehicleChargedList';
import BookingHistory from 'view/dataReports/BookingHistory';
import UserChargingHistory from 'view/dataReports/UserChargingHistory';
import StationChargingSummary from 'view/dataReports/StationChargingSummary';
import BookingSummaryReport from 'view/dataReports/BookingSummaryReport';
import MoneyTransactionPGReport from 'view/dataReports/MoneyTransactionPGReport';
import EditTenantForm from './view/tenant/EditTenantForm';
import GeneralNotification from 'view/notification/GeneralNotification';
import PermissionList from 'view/master/permissions/PermissionList';
import OfferList from 'view/offers/OfferList';
import OfferForm from 'view/offers/OfferForm';
import UserGroupList from 'view/userGroup/UserGroupList';
import UserGroupForm from 'view/userGroup/UserGroupForm';
import { InvoiceTable } from 'view/billingAndInvoice/InvoiceInfo';
import Faq from 'view/master/faqsAndPrivacy/Faq';
import SmsList from 'view/master/sms/SmsList';
import EmailList from 'view/master/email/EmailList';
import ConfigureRole from './view/master/role/ConfigureRole';
import RfidGroupList from 'view/rfidGroup/RfidGroupList';
import RfidList from 'view/rfid/RfidList';
import PricingForm from 'view/tariffManagement/PricingForm';
import StationWiseAccess from 'view/setting/StationWiseAccess';
// import HomePage from 'view/homePage';
import LoginWithPartner from 'view/login/loginWithPartner';
import BusinessPartner from 'view/businessPartner/BusinessPartner';
import AddBusinessPartner from 'view/businessPartner/AddBusinessPartner';
import AccessReport from 'view/dataReports/AccessReport';

export const MessagePopup = ({ title, description }) => {
  return (
    <div>
      <h5>{title}</h5>
      {description && <p className="m-0">{description}</p>}
    </div>
  );
};
MessagePopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect push to="/login" />
          </Route>
          {/* <Route exact path="/login" component={Login} /> */}
          <Route exact path="/login" component={LoginWithPartner} />
          <Route exact path="/loginas" component={Loginas} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <PrivateRoute exact path="/stations" component={ChargerStationList} roles={[ADMIN, TENANT_ADMIN, CPO, FINANCE, DOCO]} />
          <PrivateRoute exact path="/chargerStatus" component={ChargerStatus} roles={[ADMIN, TENANT_ADMIN, CPO, FINANCE, DOCO, SUPPORT]} />
          <PrivateRoute exact path="/stations/:stationId" component={StationDetails} roles={[ADMIN, TENANT_ADMIN, CPO]} />
          <PrivateRoute exact path={['/addStation', '/editStation/:stationId']} component={StationForm} roles={[ADMIN, TENANT_ADMIN, CPO]} />
          <PrivateRoute exact path="/charger/:chargerId?" component={ChargerDetail} roles={[ADMIN, TENANT_ADMIN, CPO, FINANCE, DOCO]} />
          <PrivateRoute exact path={['/addCharger', '/editCharger/:chargerId']} component={ChargerForm} roles={[ADMIN, TENANT_ADMIN, CPO]} />
          <PrivateRoute exact path="/userGroupManagement" component={UserGroupList} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path={['/addUserGroup', '/editUserGroup/:userGroupId']} component={UserGroupForm} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/vehicles" component={VehicleList} roles={[SUPER_ADMIN, ADMIN, TENANT_ADMIN, FINANCE, CPO, DOCO, SUPPORT]} />
          <PrivateRoute exact path={['/addVehicle', '/editVehicle/:vehicleId']} component={VehicleForm} roles={[ADMIN, TENANT_ADMIN, CPO, DOCO]} />
          <PrivateRoute exact path="/notification" component={GeneralNotification} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path={['/addOffer', '/editOffer/:offerId']} component={OfferForm} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/offers" component={OfferList} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/activityLog" component={ChargerActivityDetail} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/customers" component={CustomerList} roles={[ADMIN, TENANT_ADMIN, SUPER_ADMIN]} />
          <PrivateRoute exact path="/tariffs" component={TariffList} roles={[ADMIN, TENANT_ADMIN, FINANCE, DOCO]} />
          <PrivateRoute exact path="/businessPartner" component={BusinessPartner} roles={[ADMIN, TENANT_ADMIN, FINANCE, DOCO]} />
          <PrivateRoute exact path="/businessPartner/addBusinessPartner" component={AddBusinessPartner} roles={[ADMIN, TENANT_ADMIN, FINANCE]} />
          <PrivateRoute exact path="/editBusinessPartner/:userId" component={AddBusinessPartner} roles={[ADMIN, TENANT_ADMIN, FINANCE]} />
          <PrivateRoute
            exact
            path={['/chargingSessions', '/partnerChargingSessions']}
            component={AccessReport}
            roles={[ADMIN, TENANT_ADMIN, FINANCE, DOCO, FLEET_MEMBER]}
          />
          <PrivateRoute exact path={['/addTariff', '/editTariff/:tariffId']} component={PricingForm} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/billingInvoice" component={BillingInvoice} roles={[ADMIN, TENANT_ADMIN, FINANCE]} />
          {/* <PrivateRoute exact path="/dataReport" component={DataReportsList} roles={[ADMIN, TENANT_ADMIN]} /> */}
          <PrivateRoute exact path="/chargingSessionsReport" component={StationUnitConsumption} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/b2bStationChargingHistory" component={B2BStationChargingHistory} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/b2cStationChargingHistory" component={B2CStationChargingHistory} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/jsonRequestResponseReport" component={JsonRequestResponseReport} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/walletTransactionReport" component={WalletTransactionReport} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/appUserDetailsList" component={AppUserDetailsList} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/mostVehicleChargedList" component={MostVehicleChargedList} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/bookingHistory" component={BookingHistory} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/userChargingHistory" component={UserChargingHistory} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/stationChargingSummary" component={StationChargingSummary} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/bookingSummaryReport" component={BookingSummaryReport} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/moneyTransactionPGReport" component={MoneyTransactionPGReport} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/setting/addUser" component={UserForm} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/rfidGroup" component={RfidGroupList} roles={[ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/rfidList/:rfidId" component={RfidList} roles={[ADMIN, TENANT_ADMIN]} />
          <Route exact path="/invoice/:invoiceId" component={InvoiceTable} roles={[ADMIN, TENANT_ADMIN, FINANCE]} />

          <PrivateRoute exact path="/tenantAdmin" component={TenantAdminList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/tenantAdmin/:tenantId" component={TenantAdminDetails} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path={'/addTenant'} component={TenantForm} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path={'/editTenant/:tenantId'} component={EditTenantForm} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/subscriptions" component={SubscriptionList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/country" component={CountryList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/state" component={StateList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/city" component={CityList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/area" component={AreaList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/make" component={MakeList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/model" component={ModelList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/amenity" component={AmenityList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/electricitySupplier" component={ElectricitySupplierList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/stationAccessType" component={StationAccessTypeList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/oem" component={OemVendorList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/faq" component={FaqList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/privacy" component={PrivacyList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/connectorType" component={ConnectorTypeList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/chargeSpeed" component={ChargeSpeedList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/ChargeSpot" component={OemList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/role" component={RoleList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/configureRole/:roleId" component={ConfigureRole} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/permissions" component={PermissionList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/oemErrorCode" component={OemErrorCodeList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path={['/addChargeSpot', '/editChargeSpot/:oemId']} component={OemForm} roles={[SUPER_ADMIN]} />
          {/* <PrivateRoute exact path="/faqsAndPrivacy" component={FaqsAndPrivacy} roles={[SUPER_ADMIN]} /> */}
          <PrivateRoute exact path="/faqs" component={Faq} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/firmWareUpdate" component={FirmWareUpdate} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/chargerState" component={ChargerStateList} roles={[SUPER_ADMIN]} />
          <PrivateRoute exact path="/sms" component={SmsList} roles={[SUPER_ADMIN]} />  
          <PrivateRoute exact path="/email" component={EmailList} roles={[SUPER_ADMIN]} />

          <PrivateRoute
            exact
            path="/dashboard"
            component={Dashboard}
            roles={[SUPER_ADMIN, ADMIN, TENANT_ADMIN, FINANCE, CPO, SUPPORT, DOCO, FLEET_MEMBER]}
          />
          <PrivateRoute exact path="/setting" component={Setting} roles={[SUPER_ADMIN, ADMIN, TENANT_ADMIN, DOCO]} />
          <PrivateRoute exact path="/setting/profile" component={ProfileForm} roles={[SUPER_ADMIN, ADMIN, TENANT_ADMIN]} />
          <PrivateRoute exact path="/stationWiseAccess/:userId" component={StationWiseAccess} roles={[TENANT_ADMIN]} />
          <PrivateRoute exact path="/logout" component={Logout} roles={[SUPER_ADMIN, ADMIN, TENANT_ADMIN]} />
        </Switch>
        <ToastContainer position="top-right" theme="colored" />
        <Toaster />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
